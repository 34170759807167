import React from "react";
import {
  TextField,
  MenuItem,
  Select,
  OutlinedInput,
  FormHelperText,
  FormControl,
  Chip,
  Box,
} from "@material-ui/core";
import { FixedSizeList } from "react-window";
import styled from "styled-components/macro";
import { getPointerEventsStyles, getReadOnlyStyles } from "../lib/styles";
import CancelIcon from "@material-ui/icons/Cancel";

const ITEM_HEIGHT = 48;
const MAX_ITEM_COUNT = 8;
const MAX_MENU_HEIGHT = ITEM_HEIGHT * MAX_ITEM_COUNT;

const SelectedValuesContainer = styled(Box)`
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  max-width: 100%;
  overflow: hidden;
`;

const StyledChip = styled(Chip)`
  height: 100%;
  border-radius: 12px;
  .MuiChip-label {
    white-space: normal;
  }
`;

/** Renders selected multi-value chips with close button */
function renderSelectedValues(selected, lookupOptions, handleRemove, readOnly) {
  return (
    <SelectedValuesContainer>
      {selected.map((val) => {
        const label = lookupOptions.find((opt) => opt.id === val)?.label || val;
        return (
          <StyledChip
            key={val}
            label={label}
            deleteIcon={<CancelIcon onMouseDown={(e) => e.stopPropagation()} />}
            onDelete={!readOnly ? () => handleRemove(val) : undefined}
          />
        );
      })}
    </SelectedValuesContainer>
  );
}

function VirtualizedMenuItem({ style, option, value, onChange }) {
  const isSelected = Array.isArray(value) && value.includes(option.id);

  const handleToggle = () => {
    const newValue = isSelected
      ? value.filter((v) => v !== option.id)
      : [...(value ?? []), option.id];
    onChange(newValue);
  };

  return (
    <MenuItem
      style={{
        ...style,
        whiteSpace: "normal",
        wordWrap: "break-word",
        borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
      }}
      selected={isSelected}
      onClick={handleToggle}
    >
      {option.label}
    </MenuItem>
  );
}

function MultiSelectWrapper({ error, readOnly, children }) {
  return (
    <FormControl
      variant="outlined"
      size="small"
      fullWidth
      error={!!error}
      style={getReadOnlyStyles(readOnly)}
    >
      {children}
      {!!error && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  );
}

/** Main SelectInput Component */
export function SelectInput({
  fieldConfig,
  controllerField,
  fieldState,
  lookupOptions,
}) {
  const { type, readOnly } = fieldConfig;
  const { value, onChange, ...rest } = controllerField;
  const isMulti = type === "selectMulti";
  const useVirtualized = isMulti && lookupOptions.length >= 50;

  /** Remove a chip from selection */
  const handleRemove = (valToRemove) => {
    const newValue = value.filter((v) => v !== valToRemove);
    onChange(newValue);
  };

  /** Virtualized List Height */
  const listHeight = useVirtualized
    ? Math.min(MAX_MENU_HEIGHT, ITEM_HEIGHT * lookupOptions.length)
    : null;

  /** Single-select */
  if (!isMulti) {
    return (
      <TextField
        {...rest}
        select
        disabled={readOnly}
        value={value ?? ""}
        onChange={onChange}
        variant="outlined"
        size="small"
        error={!!fieldState.error}
        helperText={fieldState.error?.message}
        fullWidth
        style={getReadOnlyStyles(readOnly)}
        InputProps={{
          style: getPointerEventsStyles(readOnly),
        }}
      >
        {lookupOptions.map((option) => (
          <MenuItem
            key={option.id}
            value={option.id}
            style={{
              whiteSpace: "normal",
              borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
            }}
          >
            {option.label}
          </MenuItem>
        ))}
      </TextField>
    );
  }

  /** Multi-select (with or without virtualization) */
  return (
    <MultiSelectWrapper error={fieldState.error?.message} readOnly={readOnly}>
      <Select
        {...rest}
        multiple
        disabled={readOnly}
        value={value ?? []}
        onChange={onChange}
        input={<OutlinedInput />}
        renderValue={(selected) =>
          renderSelectedValues(selected, lookupOptions, handleRemove, readOnly)
        }
      >
        {useVirtualized ? (
          <FixedSizeList
            height={listHeight}
            itemCount={lookupOptions.length}
            itemSize={ITEM_HEIGHT}
            width="100%"
          >
            {({ index, style }) => (
              <VirtualizedMenuItem
                style={style}
                option={lookupOptions[index]}
                value={value}
                onChange={onChange}
              />
            )}
          </FixedSizeList>
        ) : (
          lookupOptions.map((option) => (
            <MenuItem
              key={option.id}
              value={option.id}
              style={{
                whiteSpace: "normal",
                wordWrap: "break-word",
                borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
              }}
            >
              {option.label}
            </MenuItem>
          ))
        )}
      </Select>
    </MultiSelectWrapper>
  );
}
