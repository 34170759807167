import React from "react";
import { Box } from "@material-ui/core";

const mapStyles = {
  height: "100%",
  position: "relative",
  width: "100%",
};

const Map = React.forwardRef(function Map({ children }, ref) {
  return (
    <Box component="section" style={{ position: "relative", height: "100%" }}>
      <Box ref={ref} style={mapStyles} id="public-map" tabIndex="-1" />
      {children}
    </Box>
  );
});

export default Map;
