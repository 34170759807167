import { useTheme, useMediaQuery } from "@material-ui/core";

export const useBreakpoints = () => {
  const theme = useTheme();

  return {
    isXs: useMediaQuery(theme.breakpoints.only("xs")),
    isSm: useMediaQuery(theme.breakpoints.only("sm")),
    isMd: useMediaQuery(theme.breakpoints.only("md")),
    isLg: useMediaQuery(theme.breakpoints.only("lg")),
    isXl: useMediaQuery(theme.breakpoints.only("xl")),
    isUpXs: useMediaQuery(theme.breakpoints.up("xs")),
    isUpSm: useMediaQuery(theme.breakpoints.up("sm")),
    isUpMd: useMediaQuery(theme.breakpoints.up("md")),
    isUpLg: useMediaQuery(theme.breakpoints.up("lg")),
    isUpXl: useMediaQuery(theme.breakpoints.up("xl")),
    isDownXs: useMediaQuery(theme.breakpoints.down("xs")),
    isDownSm: useMediaQuery(theme.breakpoints.down("sm")),
    isDownMd: useMediaQuery(theme.breakpoints.down("md")),
    isDownLg: useMediaQuery(theme.breakpoints.down("lg")),
  };
};
