import { z } from "zod";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax

// Utility function to generate the full Zod schema
export const generateSchema = (config) => {
  const schemaObject = config.reduce((acc, section) => {
    section.fields.forEach((field) => {
      acc[field.name] = field.validation || z.any(); // Default to `z.any()` if no validation is provided
    });
    return acc;
  }, {});

  return z.object(schemaObject);
};

export const tableNameToRouteName = (modelName) => modelName.replace(/_/g, "-");

export const routeNameToTableName = (routeName) => routeName.replace(/-/g, "_");

export const sanitizeValues = (config, values) => {
  const sanitized = { ...values };

  Object.keys(sanitized).forEach((key) => {
    const field = config
      .flatMap((section) => section.fields)
      .find((f) => f.name === key);

    if (!field) return;

    const value = sanitized[key];

    // empty strings -> null
    if (
      value === "" &&
      ["number", "selectSingle", "text", "date", "dateTime"].includes(
        field.type
      )
    ) {
      sanitized[key] = null;
    }

    // invalid selectMulti -> []
    if (field.type === "selectMulti" && !Array.isArray(value)) {
      sanitized[key] = [];
    }

    // invalid checkboxes -> null
    if (field.type === "checkbox" && typeof value !== "boolean") {
      sanitized[key] = null;
    }

    // invalid numbers -> null
    if (
      field.type === "number" &&
      (typeof value !== "number" || isNaN(value))
    ) {
      sanitized[key] = null;
    }

    // invalid dates -> null
    if (["date", "dateTime"].includes(field.type) && !isValidDate(value)) {
      sanitized[key] = null;
    }
  });

  return sanitized;
};

const isValidDate = (value) => {
  if (value === null || value === undefined) return false;
  const date = new Date(value);
  return !isNaN(date.getTime());
};

const convertToFeet = (meters) => (meters * 3.28084).toFixed(4);

const getMaxElevation = (features) => {
  if (!features?.length) return null;
  return convertToFeet(Math.max(...features.map((f) => f.properties.ele)));
};

export const fetchElevation = async (lat, lon) => {
  const response = await fetch(
    `https://api.mapbox.com/v4/mapbox.mapbox-terrain-v2/tilequery/${lon},${lat}.json?layers=contour&limit=50&access_token=${mapboxgl.accessToken}`
  );
  if (response.status !== 200) return null;
  const data = await response.json();
  return getMaxElevation(data?.features || null);
};

export const parseNumberInput = (value) => {
  const validInput = /^-?\d*\.?\d*$/.test(value);
  return validInput ? Number(value) : value;
};

export const isValidNumber = (num) =>
  typeof num === "number" && !isNaN(num) && isFinite(num);

export const isValidLat = (lat) =>
  isValidNumber(lat) && lat >= -90 && lat <= 90;

export const isValidLon = (lon) =>
  isValidNumber(lon) && lon >= -180 && lon <= 180;

export const isValidLatLon = (lat, lon) => ({
  isValidLat: isValidLat(lat),
  isValidLon: isValidLon(lon),
  isValid: isValidLat(lat) && isValidLon(lon),
});
