import { useQuery } from "react-query";
import { useAxiosInstance } from "./useAxiosInstance";
import { USE_QUERY_CACHE_OPTIONS, USE_QUERY_OPTIONS } from "../lib/constants";

export const useSelectedWell = (id) => {
  const axiosInstance = useAxiosInstance();

  return useQuery(
    ["well", id],
    async () => {
      const { data } = await axiosInstance.get(`/wells/${id}`);
      return data;
    },
    {
      ...USE_QUERY_OPTIONS,
      ...USE_QUERY_CACHE_OPTIONS,
      enabled: Boolean(id),
    }
  );
};
