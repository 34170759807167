import React from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { FieldServicesAppRoutes, WELL_ACTIONS } from "../lib/constants";
import {
  NavigationButton,
  PageContainer,
  PageAppBar,
  GridContainer,
  GridItem,
  PageRoot,
  ErrorCard,
} from "../components/ui";
import { useSelectedWell } from "../hooks/useSelectedWell";
import Skeleton from "@material-ui/lab/Skeleton";

const WellActionsPage = function () {
  const { id } = useParams();
  const { data, isFetching, error, refetch } = useSelectedWell(id);

  console.log(data);

  return (
    <PageRoot>
      <Helmet title="Well Actions | Field Services" />

      <PageAppBar
        title={
          <>
            <strong>Selected Well:</strong> {id}
          </>
        }
        buttonPath={FieldServicesAppRoutes.HOME()}
        buttonLabel="Switch Well"
      />

      <PageContainer>
        {error ? (
          <ErrorCard
            title={`Failed to Load Data for Well ${id}`}
            message={error?.message}
            onRetry={refetch}
            isFetching={isFetching}
          />
        ) : (
          <GridContainer>
            {WELL_ACTIONS(id).map((action, index) => (
              <GridItem key={index}>
                {isFetching ? (
                  <Skeleton variant="rect" style={{ flexGrow: 1 }} />
                ) : (
                  <NavigationButton to={action.route} label={action.label} />
                )}
              </GridItem>
            ))}
          </GridContainer>
        )}
      </PageContainer>
    </PageRoot>
  );
};

export default WellActionsPage;
