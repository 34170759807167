import { useQuery } from "react-query";
import { useAxiosInstance } from "./useAxiosInstance";
import { USE_QUERY_CACHE_OPTIONS, USE_QUERY_OPTIONS } from "../lib/constants";

export const useMapWells = () => {
  const axiosInstance = useAxiosInstance();

  return useQuery(
    "map-wells",
    async () => {
      const { data } = await axiosInstance.get("/map-wells");
      return data;
    },
    {
      ...USE_QUERY_OPTIONS,
      ...USE_QUERY_CACHE_OPTIONS,
    }
  );
};
