import { useMutation, useQueries, useQuery } from "react-query";
import { useAuth0 } from "@auth0/auth0-react";
import { FILENAME, USE_QUERY_OPTIONS } from "./constants";
import axios from "axios";
import {
  createValidationSchemaWithDefaults,
  extractDefaultFormValues,
  filterData,
  generateAuthHeaders,
  hasEmptyValues,
  triggerDownload,
  updateFilteredSelections,
  useCustomQuery,
} from "./utils";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useApp } from "../../../AppProvider";
import CONFIG from "./config";

const getCsvPayload = (formData, inputs) => ({
  conditions: inputs.reduce((acc, input) => {
    if (input.queryParameter) {
      acc[input.valueField] = formData[input.formName];
    }
    return acc;
  }, {}),
  excludeFields: inputs
    .filter((input) => input.excludeFields && !formData[input.formName])
    .flatMap((input) => input.excludeFields),
  excludePatterns: inputs
    .filter((input) => input.excludePatterns && !formData[input.formName])
    .flatMap((input) => input.excludePatterns),
});

const useSubmitCsv = (
  currentFocus,
  getAccessTokenSilently,
  isAuthenticated
) => {
  const { doToast } = useApp();

  const fetchDataCsv = useCallback(
    async (formData) => {
      const url = `${process.env.REACT_APP_ENDPOINT}/api/query-and-download/fetch-data-csv/${currentFocus.endpoint}`;
      const headers = await generateAuthHeaders(
        getAccessTokenSilently,
        isAuthenticated
      );
      const payload = getCsvPayload(formData, currentFocus.inputs);

      try {
        const { data } = await axios.post(url, payload, {
          headers,
          responseType: "blob",
        });
        return { csvContent: data, filename: FILENAME };
      } catch (error) {
        throw new Error("Failed to fetch data: " + error.message);
      }
    },
    [currentFocus, getAccessTokenSilently, isAuthenticated]
  );

  const { mutate, isLoading, error } = useMutation(fetchDataCsv, {
    onSuccess: ({ csvContent, filename }) => {
      triggerDownload(csvContent, filename);
      doToast("success", "CSV file successfully added to download folder.");
    },
    onError: (err) => {
      doToast("error", "Failed to download CSV file.");
      console.error("Error during file download:", err);
    },
  });

  return { mutate, isLoading, error };
};

export const useDynamicWatches = (watch, formNamesToWatch) => {
  const [watchedValues, setWatchedValues] = useState({});

  useEffect(() => {
    const updateWatchedValues = () => {
      const newWatchedValues = {};
      formNamesToWatch.forEach((name) => {
        newWatchedValues[name] = watch(name);
      });
      setWatchedValues(newWatchedValues);
    };

    updateWatchedValues();

    const subscription = watch(() => {
      updateWatchedValues();
    });

    return () => subscription.unsubscribe();
  }, [watch, formNamesToWatch]);

  return watchedValues;
};

const fetchInputData = async (
  input,
  isAuthenticated,
  getAccessTokenSilently
) => {
  const headers = await generateAuthHeaders(
    getAccessTokenSilently,
    isAuthenticated
  );
  const url = `${process.env.REACT_APP_ENDPOINT}/api/${input.filterEndpoint}`;
  const response = await axios.get(url, { headers });
  return response.data;
};

export const useQueryAndDownload = () => {
  const { isAuthenticated, getAccessTokenSilently } = useAuth0();

  const [currentFocus, setCurrentFocus] = useState(CONFIG.wells);

  const validationSchema = useMemo(
    () => createValidationSchemaWithDefaults(currentFocus),
    [currentFocus]
  );
  const defaultValues = useMemo(
    () => extractDefaultFormValues(validationSchema),
    [validationSchema]
  );

  const formMethods = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: defaultValues,
    shouldUnregister: true,
  });

  const { watch, getValues, setValue } = formMethods;

  // trigger for count query
  const formValues = watch(currentFocus.name);

  const isFormFilled = useMemo(() => !hasEmptyValues(getValues()), [getValues]);

  const [currentCount, setCurrentCount] = useState(null);

  useEffect(() => {
    if (!isFormFilled) setCurrentCount(null);
  }, [isFormFilled]);

  // if a new count request is made, the previous one is cancelled
  const cancelTokenSourceRef = useRef(axios.CancelToken.source());
  const fetchDataCount = async () => {
    const formData = getValues();
    if (hasEmptyValues(formData)) return null;
    const payload = {
      ...currentFocus.inputs.reduce((acc, input) => {
        if (input.queryParameter) {
          acc[input.valueField] = formData[input.formName];
        }
        return acc;
      }, {}),
    };
    const url = `${process.env.REACT_APP_ENDPOINT}/api/query-and-download/count-data/${currentFocus.endpoint}`;
    const headers = await generateAuthHeaders(
      getAccessTokenSilently,
      isAuthenticated
    );

    cancelTokenSourceRef.current.cancel("Cancelling previous request");
    cancelTokenSourceRef.current = axios.CancelToken.source();

    try {
      const { data } = await axios.post(url, payload, {
        headers,
        cancelToken: cancelTokenSourceRef.current.token,
      });
      return data.count;
    } catch (error) {
      if (!axios.isCancel(error)) {
        console.error("Fetch error:", error);
      }
      throw error;
    }
  };

  const { isFetching: isLoadingCurrentCount } = useQuery(
    ["data-count", isAuthenticated, formValues],
    fetchDataCount,
    {
      retry: false,
      keepPreviousData: false,
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        setCurrentCount(data);
      },
      onError: (error) => {
        setCurrentCount(null);
        console.error("Error fetching count:", error);
      },
    }
  );

  const { mutate, isLoading, error } = useSubmitCsv(
    currentFocus,
    getAccessTokenSilently,
    isAuthenticated
  );

  const onSubmit = useCallback(
    (data) => {
      mutate(data);
    },
    [mutate]
  );

  const recordsQuery = useCustomQuery(
    [
      `query-and-download/fetch-data-records/${currentFocus.endpoint}`,
      isAuthenticated,
    ],
    `query-and-download/fetch-data-records/${currentFocus.endpoint}`
  );

  const queryConfigs = useMemo(() => {
    return currentFocus.inputs
      .filter((input) => input.filterEndpoint)
      .map((input) => ({
        queryKey: [input.formName, isAuthenticated],
        queryFn: () =>
          fetchInputData(input, isAuthenticated, getAccessTokenSilently),
        ...USE_QUERY_OPTIONS,
      }));
  }, [currentFocus, isAuthenticated, getAccessTokenSilently]);

  const results = useQueries(queryConfigs);
  const inputs = useMemo(() => {
    return results.reduce((acc, result, index) => {
      const key = queryConfigs[index].queryKey[0];
      acc[key] = {
        isLoading: result.isFetching,
        isError: result.isError,
        data: result.data,
        error: result.error,
      };
      return acc;
    }, {});
  }, [queryConfigs, results]);

  const resultsDependency = useMemo(() => {
    return results.map((result) => result.dataUpdatedAt).join(",");
  }, [results]);

  useEffect(() => {
    results.forEach((result, index) => {
      const input = currentFocus.inputs.filter((i) => i.filterEndpoint)[index];
      if (result.isSuccess && input.onSuccess) {
        // Get current value from the form to check if it needs updating
        const currentValue = getValues(input.formName);
        const newData = input.onSuccess(result.data);

        // Only update if new data is different from current form value to prevent loops
        if (JSON.stringify(currentValue) !== JSON.stringify(newData)) {
          setValue(input.formName, newData);
        }
      }
    });
  }, [resultsDependency, setValue, currentFocus.inputs, getValues]); // eslint-disable-line react-hooks/exhaustive-deps

  const formInputsToWatch = useMemo(
    () =>
      currentFocus.inputs
        .filter((input) =>
          ["tristateCheckbox", "multiselect"].includes(input.type)
        )
        .map((item) => item.formName),
    [currentFocus]
  );

  const watchedValues = useDynamicWatches(watch, formInputsToWatch);

  const filteredRecords = useMemo(() => {
    const criteria = currentFocus.inputs
      .filter((input) =>
        ["tristateCheckbox", "multiselect"].includes(input.type)
      )
      .reduce((acc, item) => {
        acc[item.valueField] = watchedValues[item.formName];
        return acc;
      }, {});
    return filterData(recordsQuery?.data, criteria);
  }, [watchedValues, recordsQuery.data, currentFocus]);

  useEffect(() => {
    updateFilteredSelections(
      currentFocus.name,
      filteredRecords,
      (item) => item[currentFocus.field],
      getValues,
      setValue
    );
  }, [
    currentFocus.field,
    currentFocus.name,
    filteredRecords,
    getValues,
    recordsQuery,
    setValue,
  ]);

  const { handleSubmit, reset } = formMethods;

  const handleSubmission = useCallback(
    (event) => {
      event.preventDefault();
      handleSubmit(onSubmit)();
    },
    [handleSubmit, onSubmit]
  );

  const handleReset = useCallback(() => reset(), [reset]);

  const handleApplyNewDefaults = useCallback(
    (defaultValues) => {
      reset(defaultValues);
    },
    [reset]
  );

  const handleSelectFocus = useCallback(
    (config) => {
      setCurrentFocus(config);
      handleApplyNewDefaults(
        extractDefaultFormValues(createValidationSchemaWithDefaults(config))
      );
    },
    [setCurrentFocus, handleApplyNewDefaults]
  );

  return {
    currentFocus,
    handleSelectFocus,
    formMethods,
    handleSubmission,
    handleReset,
    isLoading,
    error,
    inputs,
    searchInput: {
      data: filteredRecords || [],
      isLoading: recordsQuery.isFetching,
      error: recordsQuery.error,
    },
    currentCount,
    isLoadingCurrentCount,
  };
};

export default useQueryAndDownload;
