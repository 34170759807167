import { lineColors } from "../../../../utils";

export const LOCATIONS_LAYER = {
  id: "locations",
  type: "circle",
  source: "locations",
  layout: {
    visibility: "visible",
  },
  paint: {
    "circle-radius": [
      "interpolate",
      ["linear"],
      ["zoom"],

      // At zoom level 5
      5,
      [
        "case",
        ["boolean", ["feature-state", "selected"], false],
        9, // Larger if selected
        3, // Default size
      ],

      // At zoom level 11
      11,
      [
        "case",
        ["boolean", ["feature-state", "selected"], false],
        13, // Larger if selected
        7, // Default size
      ],

      // At zoom level 15
      15,
      [
        "case",
        ["boolean", ["feature-state", "selected"], false],
        19, // Larger if selected
        14, // Default size
      ],
    ],
    "circle-color": [
      "case",
      ["boolean", ["feature-state", "selected"], false],
      lineColors.yellow, // Yellow when selected
      lineColors.lightBlue, // Default color
    ],
    "circle-stroke-width": [
      "case",
      ["boolean", ["feature-state", "selected"], false],
      4, // Thicker stroke for selected
      1, // Default stroke
    ],
    "circle-stroke-color": [
      "case",
      ["boolean", ["feature-state", "selected"], false],
      "#FFFFFF", // White stroke when selected
      "#000", // Default stroke
    ],
  },
  lreProperties: {
    popup: {
      titleField: "well_id",
      customLabels: {
        well_id: "SWN/District ID",
        well_status: "Status",
        well_class: "Classification",
        well_subclass: "Subclassification",
        primary_use: "Use Type",
        lat_dd: "Latitude",
        lon_dd: "Longitude",
        owner_info: "Owner",
      },
      customFields: [
        {
          label: "Edit Well",
          value: "/data-management/wells/wells-management/{well_ndx}",
          displayText: "Link",
          type: "url",
        },
      ],
      sortOrder: ["Edit Well"],
      excludeFields: [
        "well_ndx",
        "icon_ndx",
        "icon_shape",
        "icon_background_color",
        "icon_size",
        "icon_background_size",
        "icon_default_color",
        "is_monitoring",
        "in_district",
        "aquifer_group",
        "Water Levels Data",
        "Production Data",
        "has_wq_data",
        "location_geometry",
        "well_reference_name",
        "dist_property_line_ft",
        "dist_septic_ft",
        "bore_diam_in",
        "pump_depth_ft",
        "pump_size_hp",
        "pump_type",
        "pump_gpm",
        "notes_grout_types_methods",
        "created_timestamp",
        "created_by",
        "updated_timestamp",
        "updated_by",
        "well_class_ndx",
        "well_subclass_ndx",
        "primary_use_ndx",
        "secondary_use_ndx",
        "mgmtzone_ndx",
        "well_status_ndx",
        "monitoring_status_ndx",
        "district_ndx",
        "county_ndx",
        "aq_completion_ndx",
        "aquifer_ndx",
        "aq_setting_ndx",
        "driller_ndx",
        "watershed_ndx",
        "aquifer_group_ndx",
        "formation_ndx",
      ],
      adminOnlyFields: [
        "is_index_well",
        "aquifer_setting",
        "aquifer",
        "available_logs",
        "inspections_info",
        "enforcements_info",
        "driller_name",
        "directions_to_well",
      ],
    },
  },
};

export const LOCATIONS_LABELS_LAYER = {
  id: "locations-labels",
  type: "symbol",
  source: "locations",
  minzoom: 12,
  visibility: "visible",
  layout: {
    visibility: "visible",
    "symbol-placement": "point",
    "text-field": ["get", "well_id"],
    "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
    "text-size": 14,
    "text-anchor": "center",
    "text-offset": [0, -1.6],
    "text-justify": "center",
  },
  paint: {
    "text-color": "#1a1a1a",
    "text-halo-color": "rgba(255, 255, 255, 0.9)",
    "text-halo-width": 2,
    "text-halo-blur": 0.1,
  },
};

export const BASEMAP_STYLES = [
  { url: "satellite-streets-v11", icon: "satellite_alt" },
  { url: "outdoors-v11", icon: "park" },
  { url: "streets-v11", icon: "commute" },
];

export const DEFAULT_MAP_CENTER = [-98.00408, 30.10119];
export const BASEMMAP_IMAGE_CENTER = DEFAULT_MAP_CENTER;
export const DEFAULT_MAP_ZOOM = 8.5;
export const DEFAULT_MAP_BASEMAP =
  "mapbox://styles/mapbox/" + BASEMAP_STYLES[0].url;

export const INIT_MAP_CONFIG = {
  style: DEFAULT_MAP_BASEMAP,
  center: DEFAULT_MAP_CENTER,
  zoom: DEFAULT_MAP_ZOOM,
  preserveDrawingBuffer: true,
  attributionControl: true,
};
