import React, { useCallback, useEffect, useRef, useState } from "react";
import mapboxgl from "!mapbox-gl"; // eslint-disable-line import/no-webpack-loader-syntax
import { RulerControl } from "mapbox-gl-controls";
import styled, { ThemeProvider } from "styled-components/macro";
import debounce from "lodash.debounce";
import {
  jssPreset,
  StylesProvider,
  ThemeProvider as MuiThemeProvider,
} from "@material-ui/core/styles";
import "mapbox-gl-controls/lib/controls.css";
import "@mapbox/mapbox-gl-geocoder/dist/mapbox-gl-geocoder.css";
import ReactDOM from "react-dom";
import { useApp } from "../../../../AppProvider";
import ToggleBasemapControl from "../../../../components/map/ToggleBasemapControl";
import createTheme from "../../../../theme";
import { Box, Button, IconButton } from "@material-ui/core";

import LocationsControl from "../../../../components/map/LocationsControl";
import ResetZoomControl from "../../../../components/map/ResetZoomControl";
import Popup from "./Popup";
import {
  BASEMAP_STYLES,
  INIT_MAP_CONFIG,
  LOCATIONS_LABELS_LAYER,
  LOCATIONS_LAYER,
} from "./config";
import Search from "./Search";
import { create } from "jss";
import { FieldServicesAppRoutes } from "../../lib/constants";
import { useHistory } from "react-router-dom";
import { Alert, AlertTitle } from "@material-ui/lab";
import { Close } from "@material-ui/icons";

const jss = create({
  ...jssPreset(),
  insertionPoint: document.getElementById("jss-insertion-point"),
});

const popupTheme = createTheme();

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

const Root = styled(Box)`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 24px;

  ${(props) => props.theme.breakpoints.down("sm")} {
    gap: 12px;
  }
`;

const MapWrapper = styled(Box)`
  position: relative;
  height: 100%;
`;

const MapContainer = styled(Box)`
  height: 100%;
  width: 100%;
  position: relative;
`;

const SelectButton = styled(Button)`
  && {
    width: 170px;
    position: absolute;
    bottom: 16px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
  }
`;

const LocationSelectionGuide = ({ step, onClose }) => {
  if (!step || step > 3) return null;

  const messages = {
    1: "Use the search tool or interact with the map to find locations.",
    2: "Click a location to view details in a popup and zoom in to see labels.",
    3: "Click a search result or location to select it, then confirm your selection.",
  };

  return (
    <ModalBackdrop onClick={onClose}>
      <StyledAlert severity="info" variant="filled">
        <AlertTitle>
          Step {step} of {Object.keys(messages).length}
        </AlertTitle>
        {messages[step]}
        <CloseButton size="small">
          <Close fontSize="small" />
        </CloseButton>
      </StyledAlert>
    </ModalBackdrop>
  );
};

// Styled Components
const ModalBackdrop = styled(Box)`
  && {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
`;

const StyledAlert = styled(Alert)`
  && {
    position: relative;
    width: 85%;
    max-width: 400px;
    text-align: center;
    z-index: 1000;
  }
`;

const CloseButton = styled(IconButton)`
  && {
    position: absolute;
    top: 4px;
    right: 4px;
  }
`;

const Map = ({ data }) => {
  const { currentUser } = useApp();
  const history = useHistory();
  const [map, setMap] = useState();
  const [mapIsLoaded, setMapIsLoaded] = useState(false);
  const mapContainerRef = useRef(null); // create a reference to the map container
  const [locationsVisible, setLocationsVisible] = useState(true);
  const [selectedLocation, setSelectedLocation] = useState(null);
  const [step, setStep] = useState(1);
  const previousSelectedFeatureId = useRef(null);

  const handleSelectLocation = useCallback(
    (ndx) => {
      if (!ndx || !map) return;

      if (previousSelectedFeatureId.current !== null) {
        map?.setFeatureState(
          { source: "locations", id: previousSelectedFeatureId.current },
          { selected: false }
        );
      }

      map?.setFeatureState(
        { source: "locations", id: ndx },
        { selected: true }
      );

      previousSelectedFeatureId.current = ndx;

      setSelectedLocation(data.find((loc) => loc.well_ndx === ndx));
    },
    [map, data]
  );

  // Handle Search selection
  const handleSearchSelect = useCallback(
    (location) => {
      if (!location || !map) return;
      handleSelectLocation(location.well_ndx);

      const targetCoords = location.location_geometry.coordinates;

      const mapHeight = map?.getContainer().clientHeight;

      const pixelOffset = 75;
      const offset = mapHeight / 2 - pixelOffset;

      map?.flyTo({
        center: targetCoords,
        zoom: 16,
        speed: 2.4,
        curve: 0.75,
        offset: [0, offset],
      });
    },
    [handleSelectLocation, map]
  );

  const popUpRef = useRef(
    new mapboxgl.Popup({
      maxWidth: "100%",
      offset: 15,
      focusAfterOpen: false,
    })
  );
  const popupSizeRef = useRef(null);

  //create map and apply all controls
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current,
      ...INIT_MAP_CONFIG,
    });

    //top left controls
    map.addControl(new mapboxgl.FullscreenControl(), "top-left");
    map.addControl(
      new mapboxgl.GeolocateControl({
        positionOptions: { enableHighAccuracy: true },
        trackUserLocation: true,
        showUserHeading: true,
      }),
      "top-left"
    );
    map.addControl(new ResetZoomControl(), "top-left");

    //bottom left controls
    map.addControl(
      new mapboxgl.ScaleControl({ unit: "imperial" }),
      "bottom-left"
    );
    map.addControl(
      new RulerControl({
        units: "feet",
        labelFormat: (n) => `${n.toFixed(2)} ft`,
      }),
      "bottom-left"
    );

    BASEMAP_STYLES.forEach((layer) => {
      return map.addControl(
        new ToggleBasemapControl(layer.url, layer.icon),
        "bottom-right"
      );
    });

    map.on("load", () => {
      setMapIsLoaded(true);
      setMap(map);
    });
  }, []); // eslint-disable-line

  //resizes map when mapContainerRef dimensions changes (sidebar toggle)
  useEffect(() => {
    if (map) {
      const resizer = new ResizeObserver(debounce(() => map?.resize(), 100));
      resizer.observe(mapContainerRef.current);
      return () => {
        resizer.disconnect();
      };
    }
  }, [map]);

  useEffect(() => {
    if (mapIsLoaded && data?.length > 0 && typeof map != "undefined") {
      if (!map?.getSource("locations")) {
        map?.addSource("locations", {
          type: "geojson",
          data: {
            type: "FeatureCollection",
            features: data.map((location) => {
              return {
                type: "Feature",
                id: location.well_ndx,
                properties: {
                  ...location,
                },
                geometry: {
                  type: location.location_geometry.type,
                  coordinates: location.location_geometry.coordinates,
                },
              };
            }),
          },
        });

        if (!map?.getLayer("locations")) {
          map?.addLayer(LOCATIONS_LAYER);
          //add labels for locations points
          map?.addLayer(LOCATIONS_LABELS_LAYER);
        }
      }

      map?.on("click", "locations", (e) => {
        map?.fire("closeAllPopups");

        if (!e.features.length) return;

        const clickedFeature = e.features[0];
        const clickedFeatureId = clickedFeature?.properties?.well_ndx;

        handleSelectLocation(clickedFeatureId);

        const features = map?.queryRenderedFeatures(e.point);

        const coordinates = [e.lngLat.lng, e.lngLat.lat];

        const popupLayerIds = ["locations"];

        const myFeatures = features.filter((feature) =>
          popupLayerIds.includes(feature?.layer?.id)
        );

        if (myFeatures.length > 0) {
          const popupNode = document.createElement("div");
          ReactDOM.render(
            //MJB adding style providers to the popup
            <StylesProvider jss={jss}>
              <MuiThemeProvider theme={popupTheme}>
                <ThemeProvider theme={popupTheme}>
                  <Popup
                    layers={[LOCATIONS_LAYER]}
                    features={myFeatures}
                    currentUser={currentUser}
                    sizeRef={popupSizeRef}
                  />
                </ThemeProvider>
              </MuiThemeProvider>
            </StylesProvider>,
            popupNode
          );
          popUpRef.current
            .setLngLat(coordinates)
            .setDOMContent(popupNode)
            .addTo(map);
        }
      });

      map?.on("mouseenter", "locations", () => {
        map.getCanvas().style.cursor = "pointer";
      });

      map?.on("mouseleave", "locations", () => {
        map.getCanvas().style.cursor = "";
      });
    }
  }, [mapIsLoaded, map, data]); //eslint-disable-line

  const LOCATIONS_LAYERS = ["locations-labels", "locations"];
  useEffect(() => {
    if (
      map !== undefined &&
      map?.getLayer("locations") &&
      map?.getLayer("locations-labels")
    ) {
      if (!locationsVisible) {
        LOCATIONS_LAYERS.forEach((layer) =>
          map?.setLayoutProperty(layer, "visibility", "none")
        );
      } else {
        LOCATIONS_LAYERS.forEach((layer) =>
          map?.setLayoutProperty(layer, "visibility", "visible")
        );
      }
    }
  }, [locationsVisible]); // eslint-disable-line

  return (
    <Root>
      <Search onSelect={handleSearchSelect} data={data} />

      <MapWrapper component="section">
        <MapContainer ref={mapContainerRef} id="map" tabIndex={-1} />

        <LocationsControl
          open={locationsVisible}
          onToggle={() => setLocationsVisible((prev) => !prev)}
          top={10}
        />
      </MapWrapper>

      {step <= 3 && (
        <LocationSelectionGuide
          step={step}
          onClose={() => setStep((prev) => prev + 1)}
        />
      )}

      {selectedLocation?.well_id && (
        <SelectButton
          variant="contained"
          color="secondary"
          size="large"
          onClick={() =>
            history.push(
              FieldServicesAppRoutes.WELL_ACTIONS(selectedLocation.well_id)
            )
          }
        >
          Select:&nbsp;<strong>{selectedLocation.well_id}</strong>
        </SelectButton>
      )}
    </Root>
  );
};

export default Map;
