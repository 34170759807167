import { ValidationSchemas, lookupConfig } from "./formConstants";
import { z } from "zod";

export const wellRecordFormConfig = [
  {
    title: "Identity and Ownership",
    step: "Identity and Ownership",
    fields: [
      {
        name: "well_ndx",
        label: "Well NDX (Internal)",
        type: "number",
        readOnly: true,
        validation: ValidationSchemas.positiveInt,
      },
      {
        name: "district_well_id",
        label: "District Well ID",
        type: "text",
        readOnly: true,
        validation: ValidationSchemas.string,
      },
      {
        name: "state_well_num",
        label: "State Well Number",
        type: "text",
        validation: ValidationSchemas.string,
        infoTooltip: "The official well number assigned by the state.",
      },
      {
        name: "well_name",
        label: "Well Name",
        type: "text",
        validation: ValidationSchemas.string,
      },
      {
        name: "well_reference_name",
        label: "Well Reference Name",
        type: "text",
        validation: ValidationSchemas.string,
      },
      {
        name: "owner_primary_contact_name",
        label: "Primary Contact Name",
        type: "text",
        validation: ValidationSchemas.string,
      },
      {
        name: "owner_primary_contact_email",
        label: "Primary Contact Email",
        type: "text",
        validation: ValidationSchemas.email,
      },
      {
        name: "owner_primary_contact_phone",
        label: "Primary Contact Phone",
        type: "text",
        validation: ValidationSchemas.string,
      },
      {
        name: "owner_primary_contact_address",
        label: "Primary Contact Address",
        type: "text",
        validation: ValidationSchemas.string,
      },
      {
        name: "owner_primary_contact_city",
        label: "Primary Contact City",
        type: "text",
        validation: ValidationSchemas.string,
      },
      {
        name: "owner_primary_contact_state",
        label: "Primary Contact State",
        type: "text",
        validation: ValidationSchemas.string,
      },
      {
        name: "owner_primary_contact_zip",
        label: "Primary Contact Zip",
        type: "text",
        validation: ValidationSchemas.string,
      },
      {
        name: "owner_alternate_contact_name",
        label: "Alternate Contact Name",
        type: "text",
        validation: ValidationSchemas.string,
      },
      {
        name: "owner_alternate_contact_email",
        label: "Alternate Contact Email",
        type: "text",
        validation: ValidationSchemas.email,
      },
      {
        name: "owner_alternate_contact_phone",
        label: "Alternate Contact Phone",
        type: "text",
        validation: ValidationSchemas.string,
      },
    ],
  },
  {
    title: "Location",
    step: "Location",
    fields: [
      {
        name: "location_map",
        type: "map",
        label: "Location Selection",
      },
      {
        name: "lat_dd",
        label: "Latitude",
        type: "number",
        validation: ValidationSchemas.coordinate.lat,
      },
      {
        name: "lon_dd",
        label: "Longitude",
        type: "number",
        validation: ValidationSchemas.coordinate.lon,
      },
      {
        name: "elevation_ft",
        label: "Elevation (ft)",
        type: "number",
        validation: ValidationSchemas.number,
      },
      {
        name: "physical_location_address",
        label: "Physical Address",
        type: "text",
        validation: ValidationSchemas.string,
      },
      {
        name: "city",
        label: "City",
        type: "text",
        validation: ValidationSchemas.string,
      },
      {
        name: "directions_to_well",
        label: "Directions to Well",
        type: "text",
        validation: ValidationSchemas.string,
      },
      {
        name: "gate_code",
        label: "Gate Code",
        type: "text",
        validation: ValidationSchemas.string,
      },
      {
        name: "is_wellhouse",
        label: "Is Wellhouse?",
        type: "checkbox",
        validation: ValidationSchemas.boolean,
        defaultValue: false,
      },
      {
        name: "access_notes",
        label: "Access Notes",
        type: "text",
        validation: ValidationSchemas.string,
      },
    ],
  },
  {
    title: "Use",
    step: "Use",
    fields: [
      {
        name: "primary_use_ndx",
        label: "Primary Use",
        type: "selectSingle",
        lookupTable: lookupConfig.wellUse,
        validation: ValidationSchemas.positiveInt,
        defaultValue: 999,
      },
      {
        name: "secondary_use_ndx",
        label: "Secondary Use",
        type: "selectSingle",
        lookupTable: lookupConfig.wellUse,
        validation: ValidationSchemas.positiveInt.nullable().optional(),
      },
      {
        name: "well_status_ndx",
        label: "Well Status",
        type: "selectSingle",
        lookupTable: lookupConfig.wellStatus,
        validation: ValidationSchemas.positiveInt,
        defaultValue: 999,
      },
      {
        name: "monitoring_status_ndx",
        label: "Monitoring Status",
        type: "selectSingle",
        lookupTable: lookupConfig.monitoringStatus,
        validation: ValidationSchemas.positiveInt,
        defaultValue: 999,
      },
    ],
  },
  {
    title: "Setbacks",
    step: "Setbacks",
    fields: [
      {
        name: "setback_property_line_ft",
        label: "Property Line (ft)",
        type: "number",
        validation: ValidationSchemas.number,
      },
      {
        name: "setback_septic_system_ft",
        label: "Septic System (ft)",
        type: "number",
        validation: ValidationSchemas.number,
      },
      {
        name: "setback_active_wells_ft",
        label: "Active Wells (ft)",
        type: "number",
        validation: ValidationSchemas.number,
      },
      {
        name: "setback_contamination",
        label: "Contamination",
        type: "text",
        validation: ValidationSchemas.string,
      },
      {
        name: "setback_critical_environment",
        label: "Critical Environment",
        type: "text",
        validation: ValidationSchemas.string,
      },
      {
        name: "setback_notes",
        label: "Setback Notes",
        type: "text",
        validation: ValidationSchemas.string,
      },
    ],
  },
  {
    title: "Meter",
    step: "Meter",
    fields: [
      {
        name: "is_metered",
        label: "Is Metered?",
        type: "checkbox",
        validation: ValidationSchemas.boolean,
        defaultValue: false,
      },
      {
        name: "meter_sn",
        label: "Meter Serial Number",
        type: "text",
        validation: ValidationSchemas.string,
      },
      {
        name: "meter_nickname",
        label: "Meter Nickname",
        type: "text",
        validation: ValidationSchemas.string,
      },
      {
        name: "meter_accuracy_notes",
        label: "Meter Accuracy Notes",
        type: "text",
        validation: ValidationSchemas.string,
      },
      {
        name: "other_meter_notes",
        label: "Other Meter Notes",
        type: "text",
        validation: ValidationSchemas.string,
      },
      {
        name: "active_assoc_meters_ndx",
        label: "Associated Meters",
        type: "selectMulti",
        lookupTable: lookupConfig.meters,
        validation: z
          .array(ValidationSchemas.positiveInt)
          .nullable()
          .optional(),
        readOnly: true,
      },
    ],
  },
  {
    title: "Equipment",
    step: "Equipment",
    fields: [
      {
        name: "total_borehole_depth_ft",
        label: "Total Borehole Depth (ft)",
        type: "number",
        validation: ValidationSchemas.number,
      },
      {
        name: "system_type_ndx",
        label: "System Type",
        type: "selectSingle",
        lookupTable: lookupConfig.systemTypes,
        validation: ValidationSchemas.positiveInt.nullable().optional(),
      },
      {
        name: "pump_motor_size_hp",
        label: "Pump Motor Size (HP)",
        type: "number",
        validation: ValidationSchemas.number,
      },
      {
        name: "pump_type_ndx",
        label: "Pump Type",
        type: "selectSingle",
        lookupTable: lookupConfig.pumpTypes,
        validation: ValidationSchemas.positiveInt.nullable().optional(),
      },
      {
        name: "pump_depth_ft",
        label: "Pump Depth (ft)",
        type: "number",
        validation: ValidationSchemas.number,
      },
      {
        name: "flow_rate_gpm",
        label: "Flow Rate (GPM)",
        type: "number",
        validation: ValidationSchemas.number,
      },
      {
        name: "equipment_notes",
        label: "Equipment Notes",
        type: "text",
        validation: ValidationSchemas.string,
      },
    ],
  },
  {
    title: "Treatment",
    step: "Treatment",
    fields: [
      {
        name: "is_pumpsaver",
        label: "Is Pumpsaver?",
        type: "checkbox",
        validation: ValidationSchemas.boolean,
        defaultValue: false,
      },
      {
        name: "sampling_spigot_ndx",
        label: "Sampling Spigot",
        type: "selectSingle",
        lookupTable: lookupConfig.samplingSpigot,
        validation: ValidationSchemas.positiveInt.nullable().optional(),
      },
      {
        name: "storage_tank",
        label: "Has Storage Tank?",
        type: "checkbox",
        validation: ValidationSchemas.boolean,
        defaultValue: false,
      },
      {
        name: "other_treatments_ndx",
        label: "Other Treatments",
        type: "selectSingle",
        lookupTable: lookupConfig.otherTreatments,
        validation: ValidationSchemas.positiveInt.nullable().optional(),
      },
      {
        name: "treatments_notes",
        label: "Treatments Notes",
        type: "text",
        validation: ValidationSchemas.string,
      },
    ],
  },
  {
    title: "Surface Completion",
    step: "Surface Completion",
    fields: [
      {
        name: "casing_type_ndx",
        label: "Casing Type",
        type: "selectSingle",
        lookupTable: lookupConfig.casingTypes,
        validation: ValidationSchemas.positiveInt.nullable().optional(),
      },
      {
        name: "casing_height_inches",
        label: "Casing Height (inches)",
        type: "number",
        validation: ValidationSchemas.number,
      },
      {
        name: "casing_outside_diameter_inches",
        label: "Casing Outside Diameter (inches)",
        type: "number",
        validation: ValidationSchemas.number,
      },
      {
        name: "is_slab",
        label: "Is Slab?",
        type: "checkbox",
        validation: ValidationSchemas.boolean,
        defaultValue: false,
      },
      {
        name: "slab_thickness_inches",
        label: "Slab Thickness (inches)",
        type: "number",
        validation: ValidationSchemas.number,
      },
      {
        name: "slab_length_inches",
        label: "Slab Length (inches)",
        type: "number",
        validation: ValidationSchemas.number,
      },
      {
        name: "slab_width_inches",
        label: "Slab Width (inches)",
        type: "number",
        validation: ValidationSchemas.number,
      },
      {
        name: "is_steel_sleeve",
        label: "Has Steel Sleeve?",
        type: "checkbox",
        validation: ValidationSchemas.boolean,
        defaultValue: false,
      },
      {
        name: "sleeve_height_inches",
        label: "Sleeve Height (inches)",
        type: "number",
        validation: ValidationSchemas.number,
      },
      {
        name: "sleeve_outside_diameter_inches",
        label: "Sleeve Outside Diameter (inches)",
        type: "number",
        validation: ValidationSchemas.number,
      },
      {
        name: "surface_completion_notes",
        label: "Surface Completion Notes",
        type: "text",
        validation: ValidationSchemas.string,
      },
    ],
  },
  {
    title: "Classification and Reference",
    step: "Classification and Reference",
    fields: [
      {
        name: "permitted_well",
        label: "Is Permitted?",
        type: "checkbox",
        validation: ValidationSchemas.boolean,
        defaultValue: false,
        isReadOnly: true,
      },
      {
        name: "well_class_ndx",
        label: "Well Classification",
        type: "selectSingle",
        lookupTable: lookupConfig.wellClassification,
        validation: ValidationSchemas.positiveInt,
        defaultValue: 999,
      },
      {
        name: "well_subclass_ndx",
        label: "Well Subclass",
        type: "selectSingle",
        lookupTable: lookupConfig.wellSubclass,
        validation: ValidationSchemas.positiveInt,
        defaultValue: 999,
      },
      {
        name: "well_report_tracking_num",
        label: "Well Report Tracking Number",
        type: "text",
        validation: ValidationSchemas.string,
      },
      {
        name: "plugging_report_tracking_num",
        label: "Plugging Report Tracking Number",
        type: "text",
        validation: ValidationSchemas.string,
      },
      {
        name: "tx_grid_deg",
        label: "TX Grid Deg",
        type: "text",
        validation: ValidationSchemas.string,
      },
      {
        name: "tx_grid_sevmin",
        label: "TX Grid SevMin",
        type: "text",
        validation: ValidationSchemas.string,
      },
      {
        name: "tx_grid_twomin",
        label: "TX Grid TwoMin",
        type: "text",
        validation: ValidationSchemas.string,
      },
      {
        name: "district_well_id",
        label: "District Well ID",
        type: "text",
        readOnly: true,
        validation: ValidationSchemas.string,
      },
    ],
  },
  {
    title: "Record Tracking",
    step: "Record Tracking",
    adminOnly: true,
    fields: [
      {
        name: "created_timestamp",
        label: "Created Timestamp",
        type: "dateTime",
        validation: ValidationSchemas.datetime,
        readOnly: true,
      },
      {
        name: "created_by",
        label: "Created By",
        type: "text",
        validation: ValidationSchemas.string,
        readOnly: true,
      },
      {
        name: "updated_timestamp",
        label: "Updated Timestamp",
        type: "dateTime",
        validation: ValidationSchemas.datetime,
        readOnly: true,
      },
      {
        name: "updated_by",
        label: "Updated By",
        type: "text",
        validation: ValidationSchemas.string,
        readOnly: true,
      },
      {
        name: "removed_timestamp",
        label: "Removed Timestamp",
        type: "dateTime",
        validation: ValidationSchemas.datetime,
        readOnly: true,
      },
    ],
  },
];
