import React from "react";
import {
  Checkbox,
  FormControlLabel,
  FormControl,
  FormHelperText,
  Box,
} from "@material-ui/core";
import { getPointerEventsStyles, getReadOnlyStyles } from "../lib/styles";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles({
  disabledLabel: {
    "& .MuiFormControlLabel-label": {
      color: "rgba(0, 0, 0, 0.87)",
    },
  },
});

export function CheckboxInput({ fieldConfig, controllerField, fieldState }) {
  const { readOnly } = fieldConfig;
  const { value, onChange, ...rest } = controllerField;
  const classes = useStyles();

  return (
    <FormControl error={!!fieldState.error} component="fieldset" fullWidth>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        style={getReadOnlyStyles(readOnly)}
        ml="8px"
      >
        <FormControlLabel
          label={
            value === true ? "Yes" : value === false ? "No" : <i>"None"</i>
          }
          style={getPointerEventsStyles(readOnly)}
          className={readOnly && classes.disabledLabel}
          control={
            <Checkbox
              {...rest}
              disabled={readOnly}
              checked={Boolean(value)}
              onChange={(e) => onChange(e.target.checked)}
              style={getReadOnlyStyles(readOnly)}
            />
          }
        />
      </Box>
      {!!fieldState.error && (
        <FormHelperText>{fieldState.error.message}</FormHelperText>
      )}
    </FormControl>
  );
}
