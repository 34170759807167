import React, { useEffect, useRef, useState } from "react";
import { FormProvider, useWatch } from "react-hook-form";
import {
  Grid,
  Typography,
  Box,
  IconButton,
  Paper,
  CircularProgress,
  Tooltip,
  Chip,
  Stepper,
  Step,
  StepLabel,
  Button,
} from "@material-ui/core";
import { FormField } from "../inputs/FormField";
import styled from "styled-components";
import { useApp } from "../../../AppProvider";
import { FlexBox, StyledSaveButton } from "../components/ui";
import { useBreakpoints } from "../hooks/useBreakpoints";
import { Lock, Refresh } from "@material-ui/icons";
import InfoIcon from "@material-ui/icons/Info";

const SectionContainer = styled(Paper)`
  width: 100%;
  padding: 8px;
`;

const Header = styled(Typography)`
  margin-bottom: 16px;
  font-weight: bold;
`;

const StyledChip = styled(Chip)`
  white-space: nowrap;
  height: 20px;
  font-size: 0.7rem;
  background: ${(props) => props.theme.palette.background.default};

  .MuiChip-label {
    padding: 0 6px;
  }
`;

const StyledStepper = styled(Stepper).withConfig({
  shouldForwardProp: (prop) => prop !== "isMobile",
})`
  width: 100%;
  flex-wrap: ${({ isMobile }) => (isMobile ? "wrap" : "nowrap")};
  padding: 8px 0;
`;

const StyledStepLabel = styled(StepLabel).withConfig({
  shouldForwardProp: (prop) => prop !== "isMobile",
})`
  cursor: pointer;
  font-size: ${({ isMobile }) => (isMobile ? "12px" : "inherit")};
  min-width: ${({ isMobile }) => (isMobile ? "50px" : "auto")};
  padding: ${({ isMobile }) => (isMobile ? "6px" : "inherit")};
  text-align: center;
`;

const StepperContainer = styled(Box)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const Label = styled(Typography).withConfig({
  shouldForwardProp: (prop) => prop !== "isDirty" && prop !== "hasError",
})`
  font-weight: 600;
  border-left: ${({ theme, isDirty, hasError }) =>
    isDirty
      ? `4px solid ${
          hasError ? theme.palette.error.main : theme.palette.secondary.main
        }`
      : "4px solid transparent"};
  padding-left: 4px;
`;

const ResetButton = ({ onClick }) => (
  <Tooltip title="Discard changes" arrow>
    <IconButton onClick={onClick} size="small">
      <Refresh />
    </IconButton>
  </Tooltip>
);

const FieldLabel = ({ field, isDirty, hasError, infoTooltip }) => (
  <Box display="flex" alignItems="center" style={{ gap: "8px" }}>
    <Label
      color={hasError ? "error" : "textPrimary"}
      isDirty={isDirty}
      hasError={hasError}
    >
      <Tooltip title={isDirty ? "Has unsaved changes" : ""}>
        <span>{field.label}</span>
      </Tooltip>
    </Label>
    {infoTooltip && (
      <Tooltip title={infoTooltip} arrow>
        <InfoIcon fontSize="small" color="secondary" />
      </Tooltip>
    )}
  </Box>
);

const FormFieldWrapper = ({ field, formMethods, lookupOptions }) => {
  const { control, formState, resetField } = formMethods;

  const isDirty = !!formState.dirtyFields[field.name];
  const hasError = !!formState.errors[field.name];
  const isReadOnly = field?.readOnly;
  const infoTooltip = field?.infoTooltip;
  const isRequired =
    field.validation && !field.validation.safeParse(undefined).success;
  const { fn: showIfFn, deps: watchedFields } = field.showIf || {};

  const watchedValuesArray = useWatch({
    control,
    name: watchedFields || [],
  });

  const watchedValues = (watchedFields || []).reduce((acc, key, index) => {
    acc[key] = watchedValuesArray[index];
    return acc;
  }, {});

  const shouldShow = showIfFn ? showIfFn(watchedValues) : true;

  const prevShouldShow = useRef(true);
  useEffect(() => {
    if (prevShouldShow.current && !shouldShow) {
      resetField(field.name);
    }
    prevShouldShow.current = shouldShow;
  }, [shouldShow, field.name, resetField]);

  if (!shouldShow) return null;

  // Handle map separately
  if (field.type === "map") {
    return (
      <Grid item xs={12}>
        <FormField field={field} />
      </Grid>
    );
  }

  return (
    <Grid item xs={12} sm={6} md={4} lg={3}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb="8px"
      >
        <FieldLabel
          field={field}
          isDirty={isDirty}
          hasError={hasError}
          infoTooltip={infoTooltip}
        />
        {isRequired && <StyledChip label="Required" />}
      </Box>
      <Box display="flex" alignItems="center" style={{ gap: "4px" }}>
        <FormField field={field} lookupOptions={lookupOptions} />
        {!isReadOnly && isDirty && (
          <ResetButton onClick={() => resetField(field.name)} />
        )}
        {isReadOnly && (
          <Tooltip title="This field cannot be edited" arrow>
            <Lock color="disabled" />
          </Tooltip>
        )}
      </Box>
    </Grid>
  );
};

const FormSection = ({ section, formMethods, lookupsData, isAdmin }) => (
  <SectionContainer key={section.title}>
    <Header variant="h6">{section.title}</Header>
    <Grid container spacing={4}>
      {section.fields.map(
        (field) =>
          (!field?.adminOnly || isAdmin) && (
            <FormFieldWrapper
              key={field.name}
              field={field}
              formMethods={formMethods}
              lookupOptions={getLookupOptions(field, lookupsData)}
            />
          )
      )}
    </Grid>
  </SectionContainer>
);

const FormStepper = ({ stepNames, currentStepIndex, setCurrentStepIndex }) => {
  const { isDownSm } = useBreakpoints();

  return (
    <StepperContainer>
      <StyledStepper
        activeStep={currentStepIndex}
        alternativeLabel={!isDownSm}
        isMobile={isDownSm}
      >
        {stepNames.map((step, index) => (
          <Step key={index}>
            <StyledStepLabel
              isMobile={isDownSm}
              onClick={() => setCurrentStepIndex(index)}
              style={{ cursor: "pointer" }}
            >
              {!isDownSm && step}
            </StyledStepLabel>
          </Step>
        ))}
      </StyledStepper>
    </StepperContainer>
  );
};

const getLookupOptions = (field, lookupsData) =>
  field?.lookupTable?.tableName && lookupsData[field.lookupTable.tableName]
    ? lookupsData[field.lookupTable.tableName]
    : [];

const processSteps = (config) => {
  let lastStep = null;
  return config.map((section) => {
    if (section.step) lastStep = section.step;
    return { ...section, step: lastStep };
  });
};

export function DynamicForm({ config, formMethods, onSubmit, lookupsData }) {
  const { currentUser, doToast } = useApp();
  const { isDownSm, isXs } = useBreakpoints();

  const isAdmin = currentUser?.isAdmin;
  const gap = isDownSm ? "12px" : "24px";
  const buttonSize = isXs ? "medium" : "large";

  const processedConfig = processSteps(config);

  const stepGroups = processedConfig.reduce((acc, section) => {
    acc[section.step] = acc[section.step] || [];
    acc[section.step].push(section);
    return acc;
  }, {});

  const stepNames = Object.keys(stepGroups);
  const hasSteps = stepNames.length > 1;
  const [currentStepIndex, setCurrentStepIndex] = useState(0);
  const currentStepName = stepNames[currentStepIndex];

  const handleNext = () => {
    setCurrentStepIndex((prev) => prev + 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const handleBack = () => {
    setCurrentStepIndex((prev) => prev - 1);
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const onInvalid = (errors) => {
    const errorCount = Object.keys(errors).length;
    doToast(
      "error",
      `Please correct ${errorCount} error(s) before submitting.`,
      {
        persist: true,
      }
    );
    console.error("Validation errors:", errors);
  };

  return (
    <FormProvider {...formMethods}>
      <form
        style={{ width: "100%", height: "100%" }}
        onSubmit={formMethods.handleSubmit(onSubmit, onInvalid)}
      >
        <FlexBox style={{ gap }}>
          {hasSteps && (
            <FormStepper
              stepNames={stepNames}
              currentStepIndex={currentStepIndex}
              setCurrentStepIndex={setCurrentStepIndex}
            />
          )}

          {stepGroups[currentStepName]?.map(
            (section) =>
              (!section?.adminOnly || isAdmin) && (
                <Box height="100%" width="100%" key={section.title}>
                  <FormSection
                    section={section}
                    formMethods={formMethods}
                    lookupsData={lookupsData}
                    isAdmin={isAdmin}
                  />
                </Box>
              )
          )}
          {hasSteps && (
            <Button disabled={currentStepIndex === 0} onClick={handleBack}>
              Back
            </Button>
          )}

          <StyledSaveButton
            onClick={
              hasSteps && currentStepIndex < stepNames.length - 1
                ? handleNext
                : formMethods.handleSubmit(onSubmit)
            }
            variant="contained"
            color="primary"
            size={buttonSize}
            disabled={
              formMethods.formState.isSubmitting ||
              (currentStepIndex === stepNames.length - 1 &&
                (!formMethods.formState.isValid ||
                  !formMethods.formState.isDirty))
            }
          >
            {formMethods.formState.isSubmitting ? (
              <CircularProgress size={24} />
            ) : hasSteps && currentStepIndex < stepNames.length - 1 ? (
              "Next"
            ) : (
              "Submit"
            )}
          </StyledSaveButton>
        </FlexBox>
      </form>
    </FormProvider>
  );
}
