import React from "react";
import { Helmet } from "react-helmet-async";
import { FieldServicesAppRoutes } from "../lib/constants";
import {
  PageContainer,
  PageAppBar,
  PageRoot,
  GridContainer,
  GridItem,
} from "../components/ui";

const MeterReadingPage = function () {
  return (
    <PageRoot>
      <Helmet title="Enter Meter Reading | Field Services" />

      <PageAppBar
        title="Enter a Meter Reading"
        buttonPath={FieldServicesAppRoutes.HOME()}
        buttonLabel="Return to Home"
      />

      <PageContainer>
        <GridContainer>
          <GridItem></GridItem>
        </GridContainer>
      </PageContainer>
    </PageRoot>
  );
};

export default MeterReadingPage;
