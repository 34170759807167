import React from "react";
import { Helmet } from "react-helmet-async";
import { FieldServicesAppRoutes } from "../lib/constants";
import {
  PageContainer,
  PageAppBar,
  PageRoot,
  ErrorCard,
  ContentSkeleton,
} from "../components/ui";
import { useHistory } from "react-router-dom";
import { useDynamicForm } from "../hooks/useDynamicForm";
import { DynamicForm } from "../forms/DynamicForm";
import { createWellFormConfig } from "../formConfigs/createWellFormConfig";

const CreateWellPage = function () {
  const history = useHistory();
  const id = null;
  const isNewRecord = true;
  const wellNdx = null;

  const handleSuccess = (newId) => {
    const redirectId = newId || id;
    history.push(FieldServicesAppRoutes.WELL_ACTIONS(redirectId));
  };

  const { isLoading, error, refetchLookups, ...rest } = useDynamicForm({
    ndx: wellNdx,
    isNewRecord,
    config: createWellFormConfig,
    endpoints: {
      submit: "/create-well",
    },
    onSuccess: handleSuccess,
  });

  return (
    <PageRoot>
      <Helmet title="Create Well | Field Services" />
      <PageAppBar
        title={isNewRecord ? "Create a New Well" : `Well: ${id}`}
        buttonPath={
          isNewRecord
            ? FieldServicesAppRoutes.HOME()
            : FieldServicesAppRoutes.WELL_ACTIONS(id)
        }
        buttonLabel={isNewRecord ? "Return to Home" : "Return to Well Actions"}
      />
      <PageContainer>
        {error ? (
          <ErrorCard
            title={isNewRecord ? "Failed to Load" : `Failed to Load Well ${id}`}
            onRetry={async () => {
              await refetchLookups();
            }}
          />
        ) : isLoading ? (
          <ContentSkeleton />
        ) : (
          <DynamicForm config={createWellFormConfig} {...rest} />
        )}
      </PageContainer>
    </PageRoot>
  );
};

export default CreateWellPage;
