import React, {
  memo,
  useEffect,
  useMemo,
  useRef,
  useState,
  useCallback,
} from "react";
import { useQuery } from "react-query";
import {
  Box,
  Grid as MuiGrid,
  Typography as MuiTypography,
  Tooltip,
  withWidth,
  isWidthDown,
  isWidthUp,
  lighten,
  CircularProgress,
} from "@material-ui/core";
import styled, { keyframes } from "styled-components";
import axios from "axios";
import { spacing } from "@material-ui/system";
import { lineColors } from "../../../utils";
import DatePicker from "../../../components/pickers/DatePicker";
import Panel from "../../../components/panels/Panel";
import ExportDataButton from "../../../components/graphs/ExportDataButton";
import SaveRefButton from "../../../components/graphs/SaveRefButton";
import IconButton from "@material-ui/core/IconButton";
import { Close, Room } from "@material-ui/icons";
import ExpandButton from "../../../components/graphs/ExpandButton";
import TimeseriesLineChartDataViz from "../../../components/graphs/TimeseriesLineChartDataViz";
import MaterialTable from "material-table";
import Button from "@material-ui/core/Button";
import { Alert } from "@material-ui/lab";

// ------------------------------------
// STYLED COMPONENTS & CONSTANTS
// ------------------------------------
const fadeIn = keyframes`
  from { transform: scale(.25); opacity: 0; }
  to { transform: scale(1); opacity: 1; }
`;
const fadeOut = keyframes`
  from { transform: scale(1); opacity: 0; }
  to { transform: scale(.25); opacity: 1; }
`;

const OuterContainer = styled(Box)`
  margin-left: ${({ dynamicleftmargin }) => dynamicleftmargin};
  bottom: ${({ dynamicbottommargin }) => dynamicbottommargin};
  z-index: 3;
  position: absolute;
  max-height: 100%;
  width: ${({ dynamicleftmargin }) =>
    `calc(100% - ${dynamicleftmargin} - ${dynamicleftmargin})`};
  visibility: ${({ open }) => (open ? "visible" : "hidden")};
  animation: ${({ open }) => (open ? fadeIn : fadeOut)} 0.5s linear;
  transition: visibility 0.5s linear;
`;

const Viz = styled.div`
  height: ${({ height }) => height};
  max-width: 100%;
`;

const TimeseriesWrapper = styled.div`
  height: calc(100% - 96px);
  width: 100%;
`;

const TimeseriesContainer = styled.div`
  height: calc(${({ height }) => height} - 164px);
  width: 100%;
`;

const CircleMarker = styled.div`
  text-align: center;
  border-radius: 50%;
  color: white;
  background-color: ${({ theme }) => theme.palette.secondary.main};
  width: 50px;
  height: 50px;
  line-height: 66px;
  margin-right: 13px;
`;

const CloseContainer = styled.div`
  display: flex;
  justify-content: end;
  margin-top: 5px;
  margin-right: 5px;
  margin-bottom: -10px;
`;

const Grid = styled(MuiGrid)(spacing);
const Typography = styled(MuiTypography)(spacing);

const endpoint = {
  "Production Data": "ui-graph-production-final",
  "Water Levels Data": "ui-graph-daily-waterlevels",
};

// ------------------------------------
// HELPER FUNCTIONS
// ------------------------------------
function sortProductionData(results) {
  return results
    .filter((item) => item.production_gallons !== null)
    .sort((a, b) => {
      if (b.c_year !== a.c_year) return b.c_year - a.c_year;
      return b.c_month - a.c_month;
    });
}

function createProductionGraphData(data) {
  return {
    labels: data.map((item) => new Date(item.graph_date)),
    datasets: [
      {
        label: "Allocation Gallons",
        customTooltipLabel: "Annual Allocation",
        type: "line",
        yAxisID: "yR",
        pointStyle: "line",
        backgroundColor: lineColors.darkGray,
        borderColor: lineColors.darkGray,
        data: data.map((item) => item.allocation_gallons),
        pointRadius: 0,
        pointHoverRadius: 0,
        borderWidth: 4,
        spanGaps: true,
        hidden: false,
        popupInfo: data.map((item) => ({
          "Calendar Year": item.c_year,
          Month: item.calendar_month,
          "Fiscal Year": item.report_year,
        })),
      },
      {
        label: "Cumulative Annual Gallons",
        customTooltipLabel: "YTD Pumped",
        type: "line",
        yAxisID: "yR",
        pointStyle: "rect",
        stepped: "middle",
        backgroundColor: "rgba(141, 144, 147, .5)",
        borderColor: lineColors.gray,
        data: data.map((item) => item.cum_production_gallons),
        fill: true,
        pointRadius: 0,
        pointHoverRadius: 0,
        borderWidth: 2,
        spanGaps: true,
        hidden: false,
        popupInfo: data.map((item) => ({
          "Calendar Year": item.c_year,
          Month: item.calendar_month,
          "Fiscal Year": item.report_year,
        })),
      },
      {
        label: "Monthly Gallons",
        customTooltipLabel: "Gallons Pumped",
        type: "bar",
        yAxisID: "yL",
        pointStyle: "rect",
        backgroundColor: "rgba(67, 99, 215, .7)",
        borderColor: lineColors.blue,
        data: data.map((item) => item.production_gallons),
        borderWidth: 2,
        spanGaps: true,
        hidden: false,
        barPercentage: 0.2,
        popupInfo: data.map((item) => ({
          "Calendar Year": item.c_year,
          Month: item.calendar_month,
          "Fiscal Year": item.report_year,
        })),
      },
    ],
  };
}

function createWaterLevelsGraphData(data) {
  const labels = data.map((item) => new Date(item.collect_day));

  const dtwFtData = data.map((item) => item.dtw_ft);
  const hasWellDepth = data[0]?.well_depth_ft != null;
  const wellDepthData = hasWellDepth
    ? data.map((item) => item.well_depth_ft)
    : null;

  const topOfScreenData = data[0]?.top_of_screen ?? null;
  const bottomOfScreenData = data[0]?.bottom_of_screen ?? null;

  const datasets = [
    {
      label: "Depth to Water (ft)",
      customTooltipLabel: "Depth to Water",
      popupInfo: data.map((item) => ({
        "Collect Day": item.collect_day,
        "Last Measured Year": item.last_measured_year,
        "Years Measured": item.years_measured,
        "Days Measured": item.days_measured,
      })),
      backgroundColor: lighten(lineColors.blue, 0.5),
      borderColor: lineColors.blue,
      data: dtwFtData,
      pointStyle: "circle",
      borderWidth: 2,
      pointHoverRadius: 9,
      pointRadius: 7,
      fill: "start",
      type: "line",
    },
  ];

  if (hasWellDepth) {
    datasets.unshift({
      label: "Well Depth (ft)",
      customTooltipLabel: "Well Depth",
      type: "line",
      yAxisID: "yL",
      data: wellDepthData,
      borderColor: "#000000",
      borderWidth: 4,
      fill: false,
      pointRadius: 0,
    });
  }

  let annotations = {};
  if (topOfScreenData !== null || bottomOfScreenData !== null) {
    annotations = {
      annotations: {
        ...(topOfScreenData != null && {
          topOfScreenLine: {
            type: "line",
            yScaleID: "yL",
            yMin: topOfScreenData,
            yMax: topOfScreenData,
            borderColor: "black",
            borderWidth: 3,
            borderDash: [6, 6],
            display: false,
            label: {
              position: "start",
              yAdjust: -15,
              enabled: true,
              backgroundColor: "black",
              borderColor: "black",
              borderRadius: 10,
              borderWidth: 2,
              content: () => "Top of Screen: " + topOfScreenData + " ft",
            },
          },
        }),
        ...(bottomOfScreenData != null && {
          bottomOfScreenLine: {
            type: "line",
            yScaleID: "yL",
            yMin: bottomOfScreenData,
            yMax: bottomOfScreenData,
            borderColor: "black",
            borderWidth: 3,
            borderDash: [6, 6],
            display: false,
            label: {
              position: "end",
              yAdjust: 15,
              enabled: true,
              backgroundColor: "black",
              borderColor: "black",
              borderRadius: 10,
              borderWidth: 2,
              content: () => "Bottom of Screen: " + bottomOfScreenData + " ft",
            },
          },
        }),
      },
    };
  }

  return {
    graphData: {
      labels,
      datasets,
    },
    annotations,
  };
}

// ------------------------------------
// QUERY FUNCTION
// ------------------------------------
async function fetchTimeseriesData({ queryKey }) {
  const [, graphType, wellNdx] = queryKey;
  if (!graphType || !wellNdx) return [];
  const { data } = await axios.get(
    `${process.env.REACT_APP_ENDPOINT}/api/${endpoint[graphType]}/${wellNdx}`
  );
  return data;
}

// ------------------------------------
// MAIN COMPONENT
// ------------------------------------
const DataViz = ({
  open = false,
  dataVizWell,
  dataVizGraphType,
  onClose,
  width,
}) => {
  const divSaveRef = useRef(null);
  const graphSaveRef = useRef(null);
  const componentTopTableRef = useRef(null);
  const componentBottomTableRef = useRef(null);

  const defaultFilterValues = useMemo(
    () => ({
      startDate: new Date().setMonth(new Date().getMonth() - 18),
      endDate: new Date(),
    }),
    []
  );
  const [filterValues, setFilterValues] = useState(defaultFilterValues);

  const changeFilterValues = useCallback((name, value) => {
    setFilterValues((prev) => ({ ...prev, [name]: value }));
  }, []);

  const [sortedAndFilteredData, setSortedAndFilteredData] = useState(null);
  const [annotatedLines, setAnnotatedLines] = useState({});
  const [filteredMutatedGraphData, setFilteredMutatedGraphData] =
    useState(null);
  const [fetchedData, setFetchedData] = useState([]); // For second table

  // ------------------------------------
  // REACT-QUERY HOOK
  // ------------------------------------
  const {
    data: timeseriesData = [], // default to []
    isFetching: isLoading,
    isError,
    error,
  } = useQuery(
    ["timeseriesData", dataVizGraphType, dataVizWell?.well_ndx],
    fetchTimeseriesData,
    {
      enabled: !!(dataVizGraphType && dataVizWell?.well_ndx),
      refetchOnWindowFocus: false,
    }
  );

  // ------------------------------------
  // TRANSFORM & SORT DATA
  // ------------------------------------
  useEffect(() => {
    if (!timeseriesData.length) {
      setFilteredMutatedGraphData(null);
      setSortedAndFilteredData(null);
      return;
    }

    if (dataVizGraphType === "Production Data") {
      setAnnotatedLines({});
      // Resets date filters to default
      setFilterValues(defaultFilterValues);

      setSortedAndFilteredData(sortProductionData(timeseriesData));
      setFilteredMutatedGraphData(createProductionGraphData(timeseriesData));
    } else if (dataVizGraphType === "Water Levels Data") {
      // Water Levels
      // Set date filters so startDate = null, endDate = newest record
      setFilterValues((prev) => ({
        ...prev,
        startDate: null,
        endDate: new Date(timeseriesData[0]?.collect_day),
      }));

      const { graphData, annotations } =
        createWaterLevelsGraphData(timeseriesData);
      setAnnotatedLines(annotations);
      setFilteredMutatedGraphData(graphData);
      setSortedAndFilteredData(null); // Not used for water-levels
    }
  }, [timeseriesData, dataVizGraphType, defaultFilterValues]);

  // ------------------------------------
  // MISC. LOGIC
  // ------------------------------------
  const handleCustomAction = useCallback(async (rowData) => {
    try {
      const { well_ndx, c_year, c_month } = rowData;
      const response = await axios.get(
        `${process.env.REACT_APP_ENDPOINT}/api/ui-graph-production-meter-readings/${well_ndx}/${c_year}/${c_month}`
      );
      setFetchedData(response.data);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const handleToggleAnnotation = useCallback(() => {
    setAnnotatedLines((prev) => {
      if (!prev?.annotations) return prev;
      const newState = { ...prev };
      if (newState.annotations.topOfScreenLine) {
        newState.annotations.topOfScreenLine.display =
          !newState.annotations.topOfScreenLine.display;
      }
      if (newState.annotations.bottomOfScreenLine) {
        newState.annotations.bottomOfScreenLine.display =
          !newState.annotations.bottomOfScreenLine.display;
      }
      return newState;
    });
  }, []);

  // ------------------------------------
  // EXPANSION & RESIZING
  // ------------------------------------
  const [dataVizHeight, setDataVizHeight] = useState({
    viz: isWidthDown("sm", width) ? "360px" : "460px",
    timeSeries: isWidthDown("sm", width) ? "465px" : "500px",
  });

  const handleExpand = useCallback(() => {
    const { viz, timeSeries } = dataVizHeight;
    const smallVizHeights = ["460px", "360px"];
    const smallTimeseriesHeights = ["465px", "500px"];
    if (
      smallVizHeights.includes(viz) &&
      smallTimeseriesHeights.includes(timeSeries)
    ) {
      setDataVizHeight({
        viz: isWidthDown("sm", width) ? "60vh" : "70vh",
        timeSeries: isWidthDown("sm", width) ? "600px" : "860px",
      });
    } else {
      setDataVizHeight({
        viz: isWidthDown("sm", width) ? "360px" : "460px",
        timeSeries: isWidthDown("sm", width) ? "465px" : "500px",
      });
    }
  }, [dataVizHeight, width]);

  const adjustHeight = useCallback(() => {
    setDataVizHeight((prev) => {
      const { viz, timeSeries } = prev;
      const smallVizHeights = ["460px", "360px"];
      const smallTimeseriesHeights = ["465px", "500px"];

      if (
        smallVizHeights.includes(viz) &&
        smallTimeseriesHeights.includes(timeSeries)
      ) {
        return {
          viz: isWidthDown("sm", width) ? "360px" : "460px",
          timeSeries: isWidthDown("sm", width) ? "465px" : "500px",
        };
      } else {
        return {
          viz: isWidthDown("sm", width) ? "60vh" : "70vh",
          timeSeries: isWidthDown("sm", width) ? "600px" : "860px",
        };
      }
    });
  }, [width]); // ✅ Only depends on `width`, which changes less often.

  useEffect(() => {
    adjustHeight();
  }, [adjustHeight]); // ✅ Now `adjustHeight` is stable and does not cause an infinite loop.

  // ------------------------------------
  // TABLE COLUMNS
  // ------------------------------------
  const columns = useMemo(
    () => [
      {
        title: "Meter Readings",
        field: "customAction",
        cellStyle: { width: 160, minWidth: 160 },
        render: (rowData) => (
          <Button
            size="small"
            variant="contained"
            color="primary"
            onClick={async () => {
              await handleCustomAction(rowData);
              componentBottomTableRef.current.scrollIntoView({
                behavior: "smooth",
              });
            }}
          >
            Meter Readings
          </Button>
        ),
        sorting: false,
      },
      { title: "Well ID", field: "district_well_id" },
      { title: "Calendar Year", field: "c_year" },
      { title: "Month", field: "calendar_month" },
      { title: "Fiscal Year", field: "report_year" },
      { title: "Gallons Pumped", field: "production_gallons" },
      { title: "YTD Pumped", field: "cum_production_gallons" },
      { title: "Annual Allocation", field: "allocation_gallons" },
      { title: "Meter Adjustments", field: "adjustments_applied" },
    ],
    [handleCustomAction]
  );

  const columns2 = [
    { title: "Well ID", field: "district_well_id" },
    { title: "Meter ID", field: "meter_id" },
    { title: "Year", field: "c_year" },
    { title: "Month", field: "c_month" },
    { title: "Metered Gallons", field: "production_gallons" },
    { title: "Meter Adjustment", field: "adjustment_applied" },
    { title: "Adjustment Reason", field: "adjustment_reason" },
    { title: "Meter Reading", field: "meter_reading" },
    { title: "Previous Reading", field: "prev_meter_reading" },
  ];

  // ------------------------------------
  // UI HELPERS
  // ------------------------------------
  const ScrollButton = ({ targetRef, label }) => {
    const scrollToComponent = useCallback(
      () => targetRef.current.scrollIntoView({ behavior: "smooth" }),
      [targetRef]
    );
    return (
      <Button variant="contained" color="primary" onClick={scrollToComponent}>
        {label}
      </Button>
    );
  };

  const formatTableTitle = (graphType) => {
    if (graphType === "Production Data") {
      return (
        <>
          <Typography variant="h4" style={{ lineHeight: 1.3 }}>
            Reported Well Production for Well:{" "}
            <strong>{dataVizWell?.well_id ?? "NA"}</strong>
          </Typography>
          <Typography variant="subtitle1" style={{ lineHeight: 1.3 }}>
            <Box>
              Aquifer: <strong>{dataVizWell?.aquifer ?? "NA"}</strong>
            </Box>
          </Typography>
          <Typography variant="subtitle1" style={{ lineHeight: 1.3 }}>
            <Box>
              Owner: <strong>{dataVizWell?.owner_info ?? "NA"}</strong>
            </Box>
          </Typography>
          <ScrollButton
            targetRef={componentTopTableRef}
            label="Scroll Down for Data Tables"
          />
        </>
      );
    }
    if (graphType === "Water Levels Data") {
      return (
        <>
          <Typography variant="h4" style={{ lineHeight: 1.3 }}>
            Water Levels at <strong>{dataVizWell?.well_name ?? "NA"}</strong>
          </Typography>
          {/* Toggle for screening lines in water levels */}
          <Tooltip
            placement="bottom"
            title={
              !annotatedLines?.annotations?.topOfScreenLine ||
              !annotatedLines?.annotations?.bottomOfScreenLine
                ? "No screening interval data available"
                : ""
            }
          >
            <span style={{ width: "fit-content" }}>
              <Button
                size="small"
                color={
                  annotatedLines?.annotations?.topOfScreenLine?.display &&
                  annotatedLines?.annotations?.bottomOfScreenLine?.display
                    ? "primary"
                    : "default"
                }
                variant="contained"
                onClick={handleToggleAnnotation}
                disabled={
                  !annotatedLines?.annotations?.topOfScreenLine ||
                  !annotatedLines?.annotations?.bottomOfScreenLine
                }
              >
                Toggle Screening Interval
              </Button>
            </span>
          </Tooltip>
        </>
      );
    }
    return null;
  };

  // ------------------------------------
  // RENDER
  // ------------------------------------
  return (
    <OuterContainer
      bgcolor="#ffffff"
      boxShadow="0 0 0 2px rgba(0,0,0,.1)"
      borderRadius={4}
      open={open}
      dynamicleftmargin={isWidthUp("sm", width) ? "49px" : "10px"}
      dynamicbottommargin={isWidthUp("sm", width) ? "49px" : "28px"}
    >
      <Viz height={dataVizHeight.viz}>
        <CloseContainer>
          <ExpandButton
            handleExpand={handleExpand}
            expanded={!["460px", "360px"].includes(dataVizHeight.viz)}
          />
          <Tooltip title="Close" arrow>
            <IconButton
              size="small"
              onClick={onClose}
              style={{ marginLeft: "4px" }}
            >
              <Close />
            </IconButton>
          </Tooltip>
        </CloseContainer>

        {/* MAIN CONTENT */}

        <Panel overflowY="scroll" overflowX="hidden">
          {isLoading ? (
            <Box p={4} style={{ textAlign: "center" }}>
              <CircularProgress size={60} />
            </Box>
          ) : isError ? (
            <Box p={4}>
              <Alert severity="error">Error: {error?.message}</Alert>
            </Box>
          ) : (
            filteredMutatedGraphData && (
              <>
                <TimeseriesContainer
                  height={dataVizHeight.timeSeries}
                  ref={divSaveRef}
                >
                  <Box ml={4} pt={2} pb={2} display="flex" alignItems="center">
                    {isWidthUp("sm", width) && (
                      <CircleMarker>
                        <Room />
                      </CircleMarker>
                    )}
                    <Box flexDirection="column" display="flex">
                      {formatTableTitle(dataVizGraphType)}
                    </Box>
                    <Box
                      style={{ marginLeft: "auto" }}
                      data-html2canvas-ignore="true"
                      display="flex"
                    >
                      <ExportDataButton
                        title="district_well_id"
                        data={timeseriesData}
                        filterValues={filterValues}
                      />
                      <SaveRefButton
                        data-html2canvas-ignore
                        ref={divSaveRef}
                        title={dataVizWell?.well_id || "Well"}
                      />
                    </Box>
                  </Box>

                  <TimeseriesWrapper>
                    <TimeseriesLineChartDataViz
                      data={filteredMutatedGraphData}
                      yLLabel={
                        dataVizGraphType === "Water Levels Data"
                          ? "Depth to Water, Ft"
                          : "Monthly Production, Gallons"
                      }
                      yRLabel={
                        dataVizGraphType === "Production Data"
                          ? "Annual Production & Allocations, Gallons"
                          : null
                      }
                      reverseLegend
                      yLReverse={dataVizGraphType === "Water Levels Data"}
                      ref={graphSaveRef}
                      filterValues={filterValues}
                      type="line"
                      enableLegendClick
                      tooltipFormat="MM-DD-YYYY"
                      displayLegend
                      xLabelUnit="month"
                      maxTicksX={isWidthUp("sm", width) ? 12 : 6}
                      maxTicksYL={6}
                      maxTicksYR={5}
                      align={
                        dataVizGraphType === "Water Levels Data"
                          ? "center"
                          : "start"
                      }
                      beginAtZero={dataVizGraphType === "Water Levels Data"}
                      annotatedLines={annotatedLines}
                    />
                  </TimeseriesWrapper>
                </TimeseriesContainer>

                {/* Graph Date Filters */}
                <Grid
                  container
                  spacing={6}
                  alignItems="center"
                  style={{ marginTop: "20px" }}
                >
                  <Grid item xs={12} style={{ paddingBottom: 0 }}>
                    <Typography variant="h6">Graph Date Filters</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      size={isWidthDown("xs", width) ? "small" : "medium"}
                      label="Start Date"
                      name="startDate"
                      selectedDate={filterValues.startDate}
                      setSelectedDate={changeFilterValues}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <DatePicker
                      size={isWidthDown("xs", width) ? "small" : "medium"}
                      label="End Date"
                      name="endDate"
                      selectedDate={filterValues.endDate}
                      setSelectedDate={changeFilterValues}
                    />
                  </Grid>
                </Grid>

                {/* Production Data tables */}
                {dataVizGraphType === "Production Data" &&
                  !!sortedAndFilteredData?.length && (
                    <>
                      <div ref={componentTopTableRef}>
                        <MaterialTable
                          data={sortedAndFilteredData}
                          columns={columns}
                          title="Reported Well Production"
                          components={{
                            Container: (props) => <div {...props} />,
                          }}
                          options={{
                            emptyRowsWhenPaging: false,
                            exportAllData: true,
                            columnsButton: true,
                            exportButton: { csv: true },
                            padding: "dense",
                            searchFieldAlignment: "right",
                            showTitle: true,
                            pageSize: 20,
                            pageSizeOptions: [10, 20, 50, 100, 200],
                            sorting: true,
                          }}
                        />
                      </div>
                      <div ref={componentBottomTableRef}>
                        <Box flexGrow={1}>
                          <Alert icon={false} severity="info">
                            Select a row above to view the metered data for that
                            month.
                          </Alert>
                        </Box>
                        <MaterialTable
                          data={fetchedData}
                          columns={columns2}
                          title="Meter Readings"
                          components={{
                            Container: (props) => <div {...props} />,
                          }}
                          options={{
                            emptyRowsWhenPaging: false,
                            exportAllData: true,
                            columnsButton: true,
                            exportButton: { csv: true },
                            padding: "dense",
                            searchFieldAlignment: "right",
                            showTitle: true,
                            pageSize: 10,
                            pageSizeOptions: [10, 20, 50, 100, 200],
                          }}
                        />
                      </div>
                    </>
                  )}
              </>
            )
          )}
        </Panel>
      </Viz>
    </OuterContainer>
  );
};

export default withWidth()(memo(DataViz));
