export const getReadOnlyStyles = (readOnly) =>
  readOnly
    ? {
        backgroundColor: "#f5f5f5",
        color: "rgba(0, 0, 0, 0.87)",
        cursor: "not-allowed",
      }
    : {};

export const getPointerEventsStyles = (readOnly) =>
  readOnly ? { pointerEvents: "none", color: "rgba(0, 0, 0, 0.87)" } : {};
