import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";

import { FieldServicesAppRoutes } from "../lib/constants";
import {
  PageRoot,
  PageAppBar,
  PageContainer,
  ErrorCard,
  ContentSkeleton,
} from "../components/ui";
import { useSelectedWell } from "../hooks/useSelectedWell";
import { DynamicForm } from "../forms/DynamicForm";
import { wellRecordFormConfig } from "../formConfigs/wellRecordFormConfig";
import { useDynamicForm } from "../hooks/useDynamicForm";

const WellRecordPage = () => {
  const history = useHistory();
  const { id } = useParams();
  const isNewRecord = id === "new";

  const { data, isFetching, error, refetch } = useSelectedWell(
    isNewRecord ? null : id
  );

  // This is where we get our NDX from existing well OR undefined
  const wellNdx = !isNewRecord ? data?.well_ndx : null;

  const handleSuccess = (newId) => {
    const redirectId = newId || id;
    history.push(FieldServicesAppRoutes.WELL_ACTIONS(redirectId));
  };

  const {
    isLoading: isLoadingFormData,
    error: formDataError,
    refetchFormData,
    refetchLookups,
    ...rest
  } = useDynamicForm({
    ndx: wellNdx,
    isNewRecord,
    config: wellRecordFormConfig,
    endpoints: {
      fetch: "/well-actions/well-record",
      submit: "/well-actions/well-record",
    },
    onSuccess: handleSuccess,
  });

  const anyError = error || formDataError;
  const loading = isFetching || isLoadingFormData;

  return (
    <PageRoot>
      <Helmet title="Well Record | Field Services" />
      <PageAppBar
        title={isNewRecord ? "Create a New Well Record" : `Well Record: ${id}`}
        buttonPath={
          isNewRecord
            ? FieldServicesAppRoutes.HOME()
            : FieldServicesAppRoutes.WELL_ACTIONS(id)
        }
        buttonLabel={isNewRecord ? "Return to Home" : "Return to Well Actions"}
      />
      <PageContainer>
        {anyError ? (
          <ErrorCard
            title={isNewRecord ? "Failed to Load" : `Failed to Load Well ${id}`}
            message={error?.message || formDataError?.message}
            onRetry={async () => {
              await Promise.all([
                refetch(),
                refetchLookups(),
                refetchFormData(),
              ]);
            }}
          />
        ) : loading ? (
          <ContentSkeleton />
        ) : (
          <DynamicForm config={wellRecordFormConfig} {...rest} />
        )}
      </PageContainer>
    </PageRoot>
  );
};

export default WellRecordPage;
