import React from "react";
import { TextField } from "@material-ui/core";
import { getPointerEventsStyles, getReadOnlyStyles } from "../lib/styles";

export function TextInput({ fieldConfig, controllerField, fieldState }) {
  const { readOnly } = fieldConfig;
  const { value, onChange, ...rest } = controllerField;

  return (
    <TextField
      {...rest}
      multiline
      type="text"
      disabled={readOnly}
      value={value || ""}
      onChange={(e) => onChange(e.target.value)}
      variant="outlined"
      size="small"
      error={!!fieldState.error}
      helperText={fieldState.error?.message}
      style={getReadOnlyStyles(readOnly)}
      InputProps={{
        style: getPointerEventsStyles(readOnly),
      }}
      InputLabelProps={{
        style: { color: "rgba(0, 0, 0, 0.87)" },
      }}
      fullWidth
    />
  );
}
