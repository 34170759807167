import React, { forwardRef } from "react";
import {
  DatePicker,
  DateTimePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import { FormControl, FormHelperText } from "@material-ui/core";
import { getPointerEventsStyles, getReadOnlyStyles } from "../lib/styles";
import { DATE_TIME_FORMAT, DATE_FORMAT } from "../lib/constants";

export const DateInput = forwardRef(
  ({ fieldConfig, controllerField, fieldState }, ref) => {
    const { type, readOnly } = fieldConfig;
    const { value, onChange, ...rest } = controllerField;

    const PickerComponent = type === "dateTime" ? DateTimePicker : DatePicker;
    const formatString = type === "dateTime" ? DATE_TIME_FORMAT : DATE_FORMAT;

    return (
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <FormControl
          fullWidth
          error={!!fieldState.error}
          style={getReadOnlyStyles(readOnly)}
        >
          <PickerComponent
            {...rest}
            ref={ref}
            disabled={readOnly}
            value={value || null}
            onChange={onChange}
            format={formatString}
            inputVariant="outlined"
            variant="inline"
            autoOk
            disableToolbar
            style={getPointerEventsStyles(readOnly)}
            size="small"
            InputProps={{
              style: { color: "rgba(0, 0, 0, 0.87)" },
            }}
          />
          {!!fieldState.error && (
            <FormHelperText>{fieldState.error.message}</FormHelperText>
          )}
        </FormControl>
      </MuiPickersUtilsProvider>
    );
  }
);
