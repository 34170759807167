import React from "react";
import { useFormContext, Controller } from "react-hook-form";
import { TextInput } from "./TextInput";
import { NumberInput } from "./NumberInput";
import { CheckboxInput } from "./CheckboxInput";
import { SelectInput } from "./SelectInput";
import { DateInput } from "./DateInput";
import SelectCoordinatesMap from "./SelectCoordinatesMap";

export function FormField({ field: fieldConfig, lookupOptions = [] }) {
  const { name, type } = fieldConfig;
  const { control } = useFormContext();
  if (type === "map") {
    return <SelectCoordinatesMap />;
  }

  return (
    <Controller
      name={name}
      control={control}
      render={({ field: controllerField, fieldState }) => {
        switch (type) {
          case "text":
            return (
              <TextInput
                fieldConfig={fieldConfig}
                controllerField={controllerField}
                fieldState={fieldState}
              />
            );

          case "number":
            return (
              <NumberInput
                fieldConfig={fieldConfig}
                controllerField={controllerField}
                fieldState={fieldState}
              />
            );

          case "checkbox":
            return (
              <CheckboxInput
                fieldConfig={fieldConfig}
                controllerField={controllerField}
                fieldState={fieldState}
              />
            );

          case "selectSingle":
          case "selectMulti":
            return (
              <SelectInput
                fieldConfig={fieldConfig}
                controllerField={controllerField}
                fieldState={fieldState}
                lookupOptions={lookupOptions}
              />
            );

          case "date":
          case "dateTime":
            return (
              <DateInput
                fieldConfig={fieldConfig}
                controllerField={controllerField}
                fieldState={fieldState}
              />
            );

          default:
            return null;
        }
      }}
    />
  );
}
