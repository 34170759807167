import React from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import { FieldServicesAppRoutes } from "../lib/constants";
import {
  PageContainer,
  PageAppBar,
  PageRoot,
  ErrorCard,
  ContentSkeleton,
  ContentPlaceholder,
} from "../components/ui";
import { useSelectedWell } from "../hooks/useSelectedWell";

const WaterQualityPage = function () {
  const { id } = useParams();
  const { data, isFetching, error, refetch } = useSelectedWell(id);

  console.log(data);

  return (
    <PageRoot>
      <Helmet title="Water Quality | Field Services" />

      <PageAppBar
        title={
          <>
            <strong>Water Quality:</strong> {id}
          </>
        }
        buttonPath={FieldServicesAppRoutes.WELL_ACTIONS(id)}
        buttonLabel="Return to Well Actions"
      />

      <PageContainer>
        {error ? (
          <ErrorCard
            title={`Failed to Load Data for Well ${id}`}
            message={error?.message}
            onRetry={refetch}
            isFetching={isFetching}
          />
        ) : isFetching ? (
          <ContentSkeleton />
        ) : (
          <ContentPlaceholder id={id} />
        )}
      </PageContainer>
    </PageRoot>
  );
};

export default WaterQualityPage;
