import { z } from "zod";

export const ValidationSchemas = {
  string: z.string().catch(""),
  number: z.number().catch(null),
  email: z.string().email("Invalid email format").or(z.literal("")).optional(),
  boolean: z.boolean().catch(false),
  positiveInt: z.number().int().nonnegative(),
  coordinate: {
    lat: z
      .number()
      .min(-90, "Latitude must be between -90 and 90")
      .max(90, "Latitude must be between -90 and 90"),
    lon: z
      .number()
      .min(-180, "Longitude must be between -180 and 180")
      .max(180, "Longitude must be between -180 and 180"),
  },
  datetime: z.string().datetime().catch(null),
  readOnlyNullableString: z.string().nullable().default(null),
  readOnlyNullableNumber: z.number().nullable().default(null),
};

export const lookupConfig = {
  wellUse: {
    tableName: "fs_lookup_well_use",
    idField: "well_use_ndx",
    labelField: "well_use_name",
  },
  wellStatus: {
    tableName: "fs_lookup_well_status",
    idField: "status_ndx",
    labelField: "status_type_desc",
  },
  monitoringStatus: {
    tableName: "fs_lookup_monitoring_status",
    idField: "monitoring_status_ndx",
    labelField: "monitoring_status_desc",
  },
  systemTypes: {
    tableName: "fs_lookup_system_types",
    idField: "system_types_ndx",
    labelField: "system_types_desc",
  },
  pumpTypes: {
    tableName: "fs_lookup_pump_types",
    idField: "pump_types_ndx",
    labelField: "pump_types_desc",
  },
  wellClassification: {
    tableName: "fs_lookup_well_classification",
    idField: "well_class_ndx",
    labelField: "well_class_name",
  },
  wellSubclass: {
    tableName: "fs_lookup_well_subclass",
    idField: "well_subclass_ndx",
    labelField: "well_subclass_name",
  },
  meters: {
    tableName: "fs_lookup_meters",
    idField: "meter_ndx",
    labelField: "meter_info",
    additionalFields: ["meter_sn", "meter_nickname"],
  },
  samplingSpigot: {
    tableName: "fs_lookup_sampling_spigot",
    idField: "sampling_spigot_ndx",
    labelField: "sampling_spigot_desc",
    additionalFields: ["installation_date", "material_type"],
  },
  otherTreatments: {
    tableName: "fs_lookup_other_treatments",
    idField: "other_treatments_ndx",
    labelField: "other_treatments_desc",
    additionalFields: ["treatment_type", "installation_date"],
  },
  casingTypes: {
    tableName: "fs_lookup_casing_types",
    idField: "casing_types_ndx",
    labelField: "casing_types_desc",
    additionalFields: ["material_type", "standard_size"],
  },
};

export const formDefaults = {
  number: null,
  text: "",
  boolean: false,
  selectSingle: null,
  selectMulti: [],
  date: null,
  dateTime: null,
};
