/* eslint-disable import/first */
import React from "react";
import { ROUTES } from "../constants";
import { useAuth0 } from "@auth0/auth0-react";

import async from "../components/Async";

import {
  Home,
  Monitor,
  Users,
  Map,
  Droplet,
  User,
  Clipboard,
  List,
  Tool,
  Crosshair,
  HelpCircle,
} from "react-feather";

import AuthGuard from "../components/AuthGuard";
import AdminGuard from "../components/AdminGuard";
import AdminVisibilityFilter from "../components/AdminVisibilityFilter";

import Landing from "../pages/presentation/Landing";
import Default from "../pages/dashboards/Default";

// TODO MAYBE LAZY IMPORT
import PublicMap from "../pages/publicMap";
import UserVisibilityFilter from "../components/UserVisibilityFilter";
import UsersHome from "../pages/users/UsersHome";
import EditUser from "../pages/users/EditUser";
import WellsManagement from "../pages/dataManagement/wells/wellsManagement/WellsManagement";
import WellManagement from "../pages/dataManagement/wells/wellsManagement/WellManagement";
import PermitsManagement from "../pages/dataManagement/wells/PermitsManagement/PermitsManagement";
import PermitManagement from "../pages/dataManagement/wells/PermitsManagement/PermitManagement";
import ContactsManagement from "../pages/dataManagement/wells/ContactsManagement/ContactsManagement";
import ContactManagement from "../pages/dataManagement/wells/ContactsManagement/ContactManagement";
import PermitGroupManagement from "../pages/dataManagement/wells/PermitGroupsManagement/PermitGroupManagement";
import PermitGroupsManagement from "../pages/dataManagement/wells/PermitGroupsManagement/PermitGroupsManagement";
import MeterReadingsManagement from "../pages/dataManagement/wells/MeterReadingsManagement/MeterReadingsManagement";
import {
  Assignment,
  FindReplace,
  ImageSearch,
  PhonelinkSetup,
  Speed,
  SystemUpdateAlt,
  WbSunny,
} from "@material-ui/icons";
import IntersectWells from "../pages/dataAccess/tools/IntersectWells";
import MetersManagement from "../pages/dataManagement/wells/MetersManagement/MetersManagement";
import MeterManagement from "../pages/dataManagement/wells/MetersManagement/MeterManagement";
import DroughtReductionFactorsManagement from "../pages/dataManagement/wells/PermitsManagement/DroughtReductionFactorsManagement/DroughtReductionFactorsManagement";
import DroughtStage from "../pages/droughtManagement/DroughtStage/DroughtStage";
import MeterReadingsTracking from "../pages/dataManagement/wells/MetersManagement/MeterReadingsTracking";
import DroughtComplianceReport from "../pages/droughtManagement/DroughtComplianceReport/DroughtComplianceReport";
import PumpageReport from "../pages/ReportsAndAnalysis/CustomQueries/PumpageReport";
import PublicWellRegistration from "../pages/publicWellRegistration/PublicWellRegistration";
import ReviewRegistrationsManagement from "../pages/reviewPublicWellRegistrations/ReviewRegistrationsManagement";
import ReviewRegistrationManagement from "../pages/reviewPublicWellRegistrations/ReviewRegistrationManagement";
import Success from "../pages/publicWellRegistration/Success";
import QueryAndDownload from "../pages/ReportsAndAnalysis/queryAndDownload/QueryAndDownload";
import ResourceGuides from "../pages/resourceGuides/ResourceGuides";
import PhotoGallery from "../pages/dataManagement/wells/photoGallery/PhotoGallery";
import HomePage from "../pages/fieldServicesApp/pages/HomePage";
import CreateWellPage from "../pages/fieldServicesApp/pages/CreateWellPage";
import SelectWellPage from "../pages/fieldServicesApp/pages/SelectWellPage";
import MeterReadingPage from "../pages/fieldServicesApp/pages/MeterReadingPage";
import WellActionsPage from "../pages/fieldServicesApp/pages/WellActionsPage";
import WellInspectionPage from "../pages/fieldServicesApp/pages/WellInspectionPage";
import WellRecordPage from "../pages/fieldServicesApp/pages/WellRecordPage";
import WaterLevelsPage from "../pages/fieldServicesApp/pages/WaterLevelsPage";
import WaterQualityPage from "../pages/fieldServicesApp/pages/WaterQualityPage";
import UploadPhotosPage from "../pages/fieldServicesApp/pages/UploadPhotosPage";
import FieldNotesPage from "../pages/fieldServicesApp/pages/FieldNotesPage";

const Account = async(() => import("../pages/pages/Account"));
const Profile = async(() => import("../pages/pages/Profile"));

const publicMapRoutes = {
  header: "Public Resources",
  id: "Interactive Map",
  icon: <Map />,
  path: ROUTES.PUBLIC_MAP,
  name: "Interactive Map",
  component: PublicMap,
};

const publicWellRegiatrationRoute = {
  id: "Public Well Registration",
  icon: <List />,
  path: "/public-well-registration",
  name: "Public Well Registration",
  component: PublicWellRegistration,
};

const publicWellRegiatrationSuccessRoute = {
  id: "Public Well Registration Success",
  icon: <List />,
  path: "/public-well-registration/success",
  name: "Public Well Registration Success",
  component: Success,
};

// const permitApplicationsTypesRoute = {
//   id: "Public Permit Applications",
//   icon: <List />,
//   path: "/public-well-registration/types",
//   name: "Public Well Registration Types",
//   component: PermitApplicationsTypeSelection,
// };

const toolsRoutes = {
  id: "Power Tools",
  icon: <Tool />,
  children: [
    {
      internalLink: true,
      id: "Adjacent Wells",
      path: "/public-resources/tools/adjacent-wells",
      name: "Adjacent Wells",
      component: IntersectWells,
    },
  ],
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const intersectWellsRoute = {
  id: "Adjacent Wells",
  icon: <Crosshair />,
  path: "/public-tools/tools/adjacent-wells/:lat?/:lon?/:distance?",
  name: "Adjacent Wells",
  component: IntersectWells,
};

const OwnersRoutes = {
  header: "Data Management",
  id: "Well Owners",
  icon: <User />,
  children: [
    {
      path: "/data-management/well-owners/edit-owner-info",
      name: "Edit Owner Info",
      component: ContactsManagement,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
  ],
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const OwnerManagementRoute = {
  id: "Owner Management",
  icon: <Droplet />,
  path: "/data-management/well-owners/edit-owner-info/:id",
  name: "Owner Management",
  component: ContactManagement,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const WellsRoutes = {
  id: "Wells",
  icon: <Droplet />,
  children: [
    {
      path: "/data-management/wells/wells-management",
      name: "Wells Management",
      component: WellsManagement,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
    {
      path: "/data-management/wells/wells-photo-gallery",
      name: "Wells Photo Gallery",
      component: PhotoGallery,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
    {
      path: "/data-management/wells/validate-registration-submissions",
      name: "Validate Registration Submissions",
      component: ReviewRegistrationsManagement,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
  ],
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const ReviewRegistrationRoutes = {
  id: "Review Public Well Registration",
  icon: <ImageSearch />,
  path: "/data-management/wells/validate-registration-submissions/:id",
  name: "Review Public Well Registration",
  component: ReviewRegistrationManagement,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const WellManagementRoute = {
  id: "Well Management",
  icon: <Droplet />,
  path: "/data-management/wells/wells-management/:id",
  name: "Well Management",
  component: WellManagement,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const PermitsRoutes = {
  id: "Permit Records",
  icon: <Clipboard />,
  children: [
    {
      path: "/data-management/permit-records/permits-management",
      name: "Permits Management",
      component: PermitsManagement,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
    {
      path: "/data-management/permit-records/drought-reduction-factors",
      name: "Drought Reduction Factors",
      component: DroughtReductionFactorsManagement,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
    {
      path: "/data-management/permit-records/permit-groups-management",
      name: "Permit Groups Management",
      component: PermitGroupsManagement,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
  ],
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const PermitManagementRoute = {
  id: "Permit Management",
  icon: <Droplet />,
  path: "/data-management/permit-records/permits-management/:id",
  name: "Permit Management",
  component: PermitManagement,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const PermitGroupManagementRoute = {
  id: "Permit Group Management",
  icon: <Droplet />,
  path: "/data-management/permit-records/permit-groups-management/:id",
  name: "Permit Groups Management",
  component: PermitGroupManagement,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const meterReadingsRoutes = {
  id: "Meter Readings",
  icon: <Speed />,
  children: [
    {
      path: "/data-management/meter-readings/meter-readings-entry",
      name: "Meter Readings Entry",
      component: MeterReadingsManagement,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
    {
      path: "/data-management/meter-readings/add-manage-meters",
      name: "Add/Manage Meters",
      component: MetersManagement,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
    {
      path: "/data-management/meter-readings/meter-readings-tracking",
      name: "Meter Readings Tracking",
      component: MeterReadingsTracking,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
  ],
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const MeterManagementRoute = {
  id: "Meter Management",
  icon: <Droplet />,
  path: "/data-management/meter-readings/add-manage-meters/:id",
  name: "Meter Management",
  component: MeterManagement,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const FieldServicesAppHomeRoute = {
  // Home route
  id: "Field Services App",
  icon: <PhonelinkSetup />,
  path: "/field-services",
  component: HomePage,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const FieldServicesAppPagesRoutes = {
  children: [
    // Create Well route
    {
      path: "/field-services/create-well",
      component: CreateWellPage,
      guard: AdminGuard,
    },

    // Select Well route
    {
      path: "/field-services/select-well",
      component: SelectWellPage,
      guard: AdminGuard,
    },

    // Enter Meter Reading route
    {
      path: "/field-services/meter-reading",
      component: MeterReadingPage,
      guard: AdminGuard,
    },

    // Well Actions route
    {
      path: "/field-services/well-actions/:id",
      component: WellActionsPage,
      guard: AdminGuard,
    },

    // Well-specific subpages
    {
      path: "/field-services/well-actions/:id/inspection",
      component: WellInspectionPage,
      guard: AdminGuard,
    },
    {
      path: "/field-services/well-actions/:id/record",
      component: WellRecordPage,
      guard: AdminGuard,
    },
    {
      path: "/field-services/well-actions/:id/water-levels",
      component: WaterLevelsPage,
      guard: AdminGuard,
    },
    {
      path: "/field-services/well-actions/:id/water-quality",
      component: WaterQualityPage,
      guard: AdminGuard,
    },
    {
      path: "/field-services/well-actions/:id/upload-photos",
      component: UploadPhotosPage,
      guard: AdminGuard,
    },
    {
      path: "/field-services/well-actions/:id/field-notes",
      component: FieldNotesPage,
      guard: AdminGuard,
    },
  ],
};

const reportsRoutes = {
  header: "Drought Management",
  id: "Reports",
  icon: <Assignment />,
  children: [
    {
      path: "/drought-management/reports/drought-compliance-report",
      name: "Drought Compliance Report",
      component: DroughtComplianceReport,
      guard: AdminGuard,
      visibilityFilter: AdminVisibilityFilter,
    },
    // {
    //   path: "/drought-management/reports/system-drought-report",
    //   name: "System Drought Report",
    //   component: Blank,
    //   guard: AdminGuard,
    //   visibilityFilter: AdminVisibilityFilter,
    // },
  ],
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const droughtStageRoute = {
  id: "Drought Stage",
  icon: <WbSunny />,
  path: "/drought-management/drought-stage",
  name: "Drought Stage",
  component: DroughtStage,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

// const droughtDocumentsRoute = {
//   id: "Drought Documents",
//   icon: <SettingsBrightness />,
//   path: "/drought-management/drought-documents",
//   name: "Drought Stage",
//   component: Blank,
//   guard: AdminGuard,
//   visibilityFilter: AdminVisibilityFilter,
// };

const queryAndDownloadRoute = {
  header: "Reports & Analysis",
  id: "Query & Download",
  icon: <SystemUpdateAlt />,
  path: "/reports-and-analysis/query-and-download",
  name: "Query and Download",
  component: QueryAndDownload,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const customQueriesRoutes = {
  id: "Custom Queries",
  icon: <FindReplace />,
  children: [
    {
      path: "/reports-and-analysis/custom-queries/pumpage-report",
      name: "Pumpage Report",
      component: PumpageReport,
    },
  ],
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

// const bulkUploadsRoutes = {
//   id: "Bulk Uploads",
//   icon: <Publish />,
//   children: [
//     {
//       path: "/reports-and-analysis/bulk-uploads/transducer-data",
//       name: "Transducer Data",
//       component: Blank,
//     },
//   ],
//   guard: AdminGuard,
//   visibilityFilter: AdminVisibilityFilter,
// };

// const permitApplicationsSubTypesRoute = {
//   id: "Public Well Registration Subtypes",
//   icon: <List />,
//   path: "/public-well-registration/subtypes/:type",
//   name: "Public Well Registration Subtypes",
//   component: PermitApplicationsSubtypeSelection,
// };
//
// const permitsApplicationApplicationRoute = {
//   id: "Public Well Registration Registration",
//   icon: <List />,
//   path: "/public-well-registration/registration/:type/:subtype",
//   name: "Public Well Registration Registration",
//   component: PermitsApplicationsStart,
// };

const usersManagementRoutes = {
  header: "Admin",
  id: "Users Management",
  icon: <Users />,
  path: "/admin/users-management",
  name: "Users Management",
  component: UsersHome,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const resourcesRoutes = {
  id: "Resource Guides",
  icon: <HelpCircle />,
  path: "/admin/resource-guides",
  name: "Resource Guides",
  component: ResourceGuides,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

const userRoutes = {
  header: "Admin",
  id: "Users Management",
  icon: <Users />,
  path: "/admin/users-management/:id",
  name: "Users Management",
  component: EditUser,
  guard: AdminGuard,
  visibilityFilter: AdminVisibilityFilter,
};

// const permitApplicationManagementRoutes = {
//   id: "Public Well Registration Management",
//   icon: <Settings style={{ width: "32px" }} />,
//   children: [
//     {
//       path: "/admin/public-well-registration-management/types",
//       name: "Registration Types",
//       component: ApplicationTypesManagement,
//       guard: AdminGuard,
//       visibilityFilter: AdminVisibilityFilter,
//     },
//     {
//       path: "/admin/public-well-registration-management/subtypes",
//       name: "Registration Subtypes",
//       component: ApplicationSubtypesManagement,
//       guard: AdminGuard,
//       visibilityFilter: AdminVisibilityFilter,
//     },
//   ],
//   guard: AdminGuard,
//   visibilityFilter: AdminVisibilityFilter,
// };

// const publicFilesRoutes = {
//   id: "Public Files",
//   header: "Documents",
//   icon: <Archive />,
//   path: "/documents/public-files",
//   name: "Public Files",
//   component: Blank,
//   guard: AdminGuard,
//   visibilityFilter: AdminVisibilityFilter,
// };
//
// const clientDocsRoutes = {
//   id: "Client Docs",
//   icon: <Folder />,
//   path: "/documents/client-docs",
//   name: "Client Documents",
//   component: Blank,
//   guard: AdminGuard,
//   visibilityFilter: AdminVisibilityFilter,
// };

const accountRoutes = {
  id: "Account",
  path: "/account",
  name: "Account",
  header: "Pages",
  icon: <Users />,
  component: Account,
  children: [
    {
      path: ROUTES.USER_PROFILE,
      name: "Profile",
      component: Profile,
    },
    {
      path: "/auth/logout",
      name: "Logout",
      component: function Logout() {
        const { logout } = useAuth0();
        logout();
      },
    },
  ],
  guard: AuthGuard,
};

const landingRoutes = {
  id: "Landing Page",
  path: "/",
  header: "Docs",
  icon: <Monitor />,
  component: Landing,
  children: null,
};

const mainRoutes = {
  id: "Dashboard",
  path: "/dashboard",
  icon: <Home />,
  component: Default,
  children: null,
  containsHome: true,
  guard: AuthGuard,
  visibilityFilter: UserVisibilityFilter,
};

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = [
  mainRoutes,
  toolsRoutes,
  intersectWellsRoute,
  meterReadingsRoutes,
  usersManagementRoutes,
  resourcesRoutes,
  userRoutes,
  // permitApplicationManagementRoutes,
  accountRoutes,
  PermitsRoutes,
  PermitManagementRoute,
  // permitApplicationsTypesRoute,
  // permitApplicationsSubTypesRoute,
  // permitsApplicationApplicationRoute,
  WellsRoutes,
  WellManagementRoute,
  PermitGroupManagementRoute,
  OwnersRoutes,
  OwnerManagementRoute,
  MeterManagementRoute,
  reportsRoutes,
  droughtStageRoute,
  queryAndDownloadRoute,
  customQueriesRoutes,
  publicWellRegiatrationRoute,
  publicWellRegiatrationSuccessRoute,
  ReviewRegistrationRoutes,
];

// Routes using the max content layout
export const dashboardMaxContentLayoutRoutes = [
  publicMapRoutes,
  FieldServicesAppHomeRoute,
  FieldServicesAppPagesRoutes,
];

// Routes using the Auth layout
export const authLayoutRoutes = [];

// Routes using the Presentation layout
export const presentationLayoutRoutes = [landingRoutes];

// Routes using the full screen map layout
export const fullscreenMapRoutes = [];

// Routes visible in the sidebar
export const sidebarRoutes = [
  mainRoutes,
  publicMapRoutes,
  publicWellRegiatrationRoute,
  // permitApplicationsTypesRoute,
  toolsRoutes,
  OwnersRoutes,
  WellsRoutes,
  PermitsRoutes,
  // reportsRoutes,
  meterReadingsRoutes,
  // FieldServicesAppHomeRoute,
  reportsRoutes,
  droughtStageRoute,
  queryAndDownloadRoute,
  customQueriesRoutes,
  usersManagementRoutes,
  resourcesRoutes,
  // permitApplicationManagementRoutes,
];
